// Dependencies
import { FC } from 'react';

// Routes
import { ROUTES } from './config';

// Pages
import {
    SignInPage,
    SignUpPage,
    CartPage,
    CheckoutPage,
    HomePage,
    OrderDetailPage,
    ProductDetailPage,
    ProfilePage,
    ShopPage,
    SearchResultPage,
    UsPage,
    DepositPage,
    WithdrawPage,
    PrivacyStatementPage,
    TermsConditionsPage,
    HelpCenterPage,
    FAQPage,
    FAQGroupPage,
    FAQDetailPage,
    TicketListPage,
    TicketFormPage,
    ArticleListPage,
    ArticleDetailPage,
    ForgotPassword,
    ResetPassword,
    CategoryPage,
    ArtistListPage,
    ArtistDetailPage,
    WalletDetailPage,
    GalleryDetailPage
} from '../pages';
import { Verify } from '../pages/Auth/Verify';
import { OrderNftDetailPage } from '../pages/OrderDetail/NftDetail';
import { ConfirmEmailPage } from '../pages/Auth/SignUp/ConfirmEmail';

// Interface
export interface IRoute {
    id: string;
    name: string;
    path: string;
    component: any;
    children?: IRoute[] | null;
    guard?: FC;
}

export const AUTH_ROUTES: IRoute[] = [
    {
        id: 'SignIn',
        name: 'SignIn',
        path: ROUTES.AUTH.SIGN_IN,
        component: SignInPage,
        children: null
    },
    {
        id: 'SignUp',
        name: 'SignUp',
        path: ROUTES.AUTH.SIGN_UP,
        component: SignUpPage,
        children: null
    },
    {
        id: 'ForgotPassword',
        name: 'ForgotPassword',
        path: ROUTES.AUTH.FORGOTPASSWORD,
        component: ForgotPassword,
    },
    {
        id: 'ConfirmEmail',
        name: 'ConfirmEmail',
        path: ROUTES.AUTH.CONFIRM_EMAIL,
        component: ConfirmEmailPage,
    },
    {
        id: 'ResetPassword',
        name: 'ResetPassword',
        path: ROUTES.AUTH.RESET_PASSWORD,
        component: ResetPassword,
    },
    {
        id: 'Verify',
        name: 'Verify',
        path: ROUTES.AUTH.VERIFY,
        component: Verify,
    },
];

export const MAIN_ROUTES: IRoute[] = [
    {
        id: 'Home',
        name: 'Home',
        path: ROUTES.HOME,
        component: HomePage,
        children: null
    },
    {
        id: 'Shop',
        name: 'Shop',
        path: ROUTES.SHOP.INDEX,
        component: ShopPage,
        children: null
    },
    {
        id: 'ProductsByCategory',
        name: 'ProductsByCategory',
        path: ROUTES.PRODUCTS.BY_CATEGORY,
        component: ShopPage,
        children: null
    },
    {
        id: 'events',
        name: 'Events',
        path: ROUTES.EVENTS.INDEX,
        component: ShopPage
    },
    {
        id: 'product-detail',
        name: 'Product Detail',
        path: ROUTES.PRODUCTS.DETAIL,
        component: ProductDetailPage
    },
    {
        id: 'Us',
        name: 'Us',
        path: ROUTES.US,
        component: UsPage
    },
    {
        id: 'Cart',
        name: 'Cart',
        path: ROUTES.CART,
        component: CartPage,
        children: null
    },
    {
        id: 'Category',
        name: 'Category',
        path: ROUTES.CATEGORY,
        component: CategoryPage,
        children: null
    },
    {
        id: 'Checkout',
        name: 'Checkout',
        path: ROUTES.CHECKOUT,
        component: CheckoutPage,
        children: null
    },
    {
        id: 'Profile',
        name: 'Profile',
        path: ROUTES.PROFILE.INDEX,
        component: ProfilePage,
        children: null
    },
    {
        id: 'SearchResult',
        name: 'SearchResult',
        path: ROUTES.SEARCH_RESULT,
        component: SearchResultPage
    },
    {
        id: 'DepositCrypto',
        name: 'DepositCrypto',
        path: ROUTES.CRYPTO.DEPOSIT,
        component: DepositPage
    },
    {
        id: 'WithdrawCrypto',
        name: 'WithdrawCrypto',
        path: ROUTES.CRYPTO.WITHDRAW,
        component: WithdrawPage
    },
    {
        id: 'OrderDetail',
        name: 'OrderDetail',
        path: ROUTES.ORDERS.DETAIL,
        component: OrderDetailPage
    },
    {
        id: 'OrderNftDetail',
        name: 'OrderNftDetail',
        path: ROUTES.ORDERS.NFTDETAIL,
        component: OrderNftDetailPage
    },
    {
        id: 'PrivacyStatement',
        name: 'PrivacyStatement',
        path: ROUTES.PRIVACY_STATEMENT,
        component: PrivacyStatementPage
    },
    {
        id: 'TermsConditions',
        name: 'TermsConditions',
        path: ROUTES.TERMS_CONDITIONS,
        component: TermsConditionsPage
    },
    {
        id: 'Articles',
        name: 'Articles',
        path: ROUTES.ARTICLE.LIST,
        component: ArticleListPage
    },
    {
        id: 'Article Detail',
        name: 'Article Detail',
        path: ROUTES.ARTICLE.DETAIL,
        component: ArticleDetailPage
    },
    {
        id: 'Artists',
        name: 'Artists',
        path: ROUTES.ARTIST.LIST,
        component: ArtistListPage
    },
    {
        id: 'Artist Detail',
        name: 'Artist Detail',
        path: ROUTES.ARTIST.DETAIL,
        component: ArtistDetailPage
    },
    {
        id: 'Gallery Detail',
        name: 'Gallery Detail',
        path: ROUTES.ARTIST.GALLERY_DETAIL,
        component: GalleryDetailPage
    },
    {
        id: 'Wallet Detail',
        name: 'Wallet Detail',
        path: ROUTES.PROFILE.WALLET.DETAIL,
        component: WalletDetailPage
    },
];

export const HELP_CENTER_ROUTES: IRoute[] = [
    {
        id: 'HelpCenter',
        name: 'HelpCenter',
        path: ROUTES.HELP_CENTER.INDEX,
        component: HelpCenterPage
    },
    {
        id: 'FAQ',
        name: 'FAQ',
        path: ROUTES.HELP_CENTER.FAQ.INDEX,
        component: FAQPage
    },
    {
        id: 'FAQGroup',
        name: 'FAQGroup',
        path: ROUTES.HELP_CENTER.FAQ.GROUP,
        component: FAQGroupPage
    },
    {
        id: 'FAQDetail',
        name: 'FAQDetail',
        path: ROUTES.HELP_CENTER.FAQ.DETAIL,
        component: FAQDetailPage
    },
    {
        id: 'TicketList',
        name: 'TicketList',
        path: ROUTES.HELP_CENTER.TICKETS.INDEX,
        component: TicketListPage
    },
    {
        id: 'TicketForm',
        name: 'TicketForm',
        path: ROUTES.HELP_CENTER.TICKETS.FORM,
        component: TicketFormPage
    },
    {
        id: 'TicketEdit',
        name: 'TicketEdit',
        path: ROUTES.HELP_CENTER.TICKETS.EDIT,
        component: TicketFormPage
    }
];
