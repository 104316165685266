// Dependencies
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Button, Input, useToast, FormControl, FormHelperText } from '@chakra-ui/core';

// Components
import { Alert, AnimationOnScroll, Icon } from '../../../components';

// Apis
import { AuthApi } from '../../../apis';

// Store
import { setUser } from '../../../store/actions';

// Constants
import { ACCESS_TOKEN_KEY, ROUTES } from '../../../constants';

// Styles
import './styles.scss';
import { UserModel } from '../../../shared/models';

// Interfaces
interface ISignInValues {
  email: string;
  password: string;
  submit?: string;
}

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid!').required('Email is required!'),
  password: Yup.string().required('Password is required.').min(8, 'Password must be at least 8 characters!')
});

// Export sign-in page
export const SignInPage: FC = () => {
  // Get history from hook
  const history = useHistory();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get toast from hook
  const toast = useToast();

  // Create account handler
  const handleCreateAccount = () => {
    history.push(ROUTES.AUTH.SIGN_UP);
  };

  // Submit handler
  const handleSubmit = (values: ISignInValues, { setSubmitting }: FormikHelpers<ISignInValues>) => {
    const { email, password } = values;

    AuthApi.login(email, password)
      .then((res) => {
        if (!res.user.verify) {
          setSubmitting(false);
          return toast({
            position: 'top-right',
            render: ({ onClose }) => <Alert message="Account is not activated. Checkout your inbox!" onClose={onClose} />
          });
        }
        const user = new UserModel(res.user);
        if (user) {
          dispatch(setUser(user));
        }

        if (res.token) {
          localStorage.setItem(ACCESS_TOKEN_KEY, res.token);
        }
        history.push(ROUTES.HOME);

        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message="Successfully Sign in!" onClose={onClose} />
        });
      })
      .catch((err) => {
        const message = err.msg || 'Something went wrong';
        setSubmitting(false);
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={message} color="red" onClose={onClose} />
        });
      });
  };

  // Return sign-in page
  return (
    <div className="sign-in-page">
      <AnimationOnScroll animation="animate__bounce" delay={2}>
        <div className="page-title auth-page-title">
          <h2 className="text-heading2">Welcome.</h2>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animation="animate__fadeIn" delay={1} className="content">
        <div className="actions">
          <div className="action-buttons">
            <Button
              className="d-button d-button--full-width"
              leftIcon={() => <Icon name="create-account" />}
              onClick={handleCreateAccount}
            >
              Create account
            </Button>
            <Button className="d-button  d-button--full-width" leftIcon={() => <Icon name="apple" />}>
              Login with Apple
            </Button>
            <Button className="d-button  d-button--full-width" leftIcon={() => <Icon name="google" />}>
              Login with Google
            </Button>
          </div>
        </div>
        <div className="divider" />
        <Formik
          initialValues={{ name: '', email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form className="form" onSubmit={handleSubmit}>
              <div className="form-content">
                <FormControl className="d-form-control" isInvalid={Boolean(errors.email && touched.email)}>
                  <Input
                    className="d-form-input"
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText className="d-form-helper-text">
                    {errors.email && touched.email && String(errors.email)}
                  </FormHelperText>
                </FormControl>
                <FormControl className="d-form-control" isInvalid={Boolean(errors.password && touched.password)}>
                  <Input
                    className="d-form-input"
                    placeholder="Password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText className="d-form-helper-text">
                    {errors.password && touched.password && String(errors.password)}
                  </FormHelperText>
                </FormControl>
                <Button type="submit" className="d-button d-button--full-width" isLoading={isSubmitting}>
                  Login
                </Button>
                <Link to="/forgot-password" className="text-body3 text-body3--lg">
                  Forgot Password?
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </AnimationOnScroll>
      <div className="hand-img" />
    </div>
  );
};
