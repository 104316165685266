// Dependencies
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Spinner, useToast } from '@chakra-ui/core';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import moment from 'moment';

// Components
import { Alert, AnimationOnScroll, Icon, IconButton, IIcon } from '../../../../components';

// Apis
import { OrderApi, UserApi } from '../../../../apis';

// Utils
import { formatDate } from '../../../../utils';

// Store
import { getUser } from '../../../../store/selectors';

// Styles
import './styles.scss';

// Interfaces
import { IMessage, IOrder, IShopHistory, ISortOrder, OrderStatus } from '../../../../shared/interfaces';

// Global constants
import { REACT_APP_API_ASSET_SERVER, ROUTES } from '../../../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../../../utils/generaltittlefunction';

// Constants
enum OrderTab {
    Processing = 'Processing',
    Delivered = 'Delivered',
    Cancelled = 'Cancelled'
}

const defaultOrdersByStatus = [
    {
        icon: 'processing',
        status: OrderTab.Processing,
        count: 0
    },
    {
        icon: 'shopping-bag-check',
        status: OrderTab.Delivered,
        count: 0
    },
    {
        icon: 'shopping-bag-x',
        status: OrderTab.Cancelled,
        count: 0
    }
];

// Export dashboard tab
export const DashboardTab = () => {
    // States
    const [shopHistories, setShopHistories] = useState<IShopHistory[]>([]);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const [visibleOrders, setVisibleOrders] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<OrderTab>(OrderTab.Processing);
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [allOrders, setAllOrders] = useState<IOrder[]>([]);

    // Get toast from hook
    const toast = useToast();

    // Get history from hook
    const history = useHistory();

    // Get user from store
    const user = useSelector(getUser);

    // Get location form hook
    const { state } = useLocation<{ tab: OrderTab }>();

    // Fetch orders
    const fetchOrders = useCallback(() => {
        OrderApi.readAll({
            query: {
                userId: user?.id
            }
        })
            .then((res) => {
                setAllOrders(res.orders);
            })
            .catch((err) => console.log(err));
    }, [user]);

    // Fetch messages
    const fetchMessages = useCallback(() => {
        setLoading(true);
        UserApi.getMessages({
            options: {
                sort: {
                    updatedAt: ISortOrder.DESC
                }
            }
        })
            .then((res) => setMessages(res.messages))
            .catch((err) => {
                toast({
                    position: 'top-right',
                    render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
                });
            })
            .finally(() => setLoading(false));
    }, [toast]);

    // Fetch shop history
    const fetchShopHistory = useCallback(() => {
        if (shopHistories) {
            // fix me
        }
        if (user) {
            UserApi.getShopHistory(user?.id)
                .then((res) => setShopHistories(res.history))
                .catch((err) => console.log(err));
        }
    }, [shopHistories, user]);

    const filterOrderByStatus = useCallback((orderStatus: OrderTab) => {
        return allOrders.filter(({ status }) => {
            switch (orderStatus) {
                case OrderTab.Processing: {
                    return status === OrderStatus.Created;
                }

                case OrderTab.Delivered: {
                    return status === OrderStatus.Processed;
                }

                case OrderTab.Cancelled: {
                    return status === OrderStatus.Cancelled;
                }

                default:
                    return false;
            }
        });
    }, [allOrders]);

    const ordersByStatus = useMemo(() => {
        return defaultOrdersByStatus.map((order) => ({
            ...order,
            count: filterOrderByStatus(order.status).length
        }));
    }, [filterOrderByStatus]);

    // Order by status click handler
    const handleClickOrderByStatus = (tab: OrderTab) => {
        setVisibleOrders(true);
        setActiveTab(tab);
    };

    // Tab click handler
    const handleTabClick = (tab: OrderTab) => {
        setActiveTab(tab);
    };

    // Delete message handler
    const handleDeleteMessage = (id: string) => {
        UserApi.removeMessage(id)
            .then(() => {
                const updatedMessages = messages.filter((m) => m.id !== id);
                setMessages([...updatedMessages]);
                toast({
                    position: 'top-right',
                    render: ({ onClose }) => <Alert message={'Message is removed successfully.'} onClose={onClose} />
                });
            })
            .catch((err) => {
                toast({
                    position: 'top-right',
                    render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
                });
            });
    };

    // Open order detail modal handler
    const handleOpenOrderDetailModal = (id: string) => {
        history.push(ROUTES.ORDERS.DETAIL.replace(':id', id));
    };

    // On allOrders changed
    useEffect(() => {
        const newOrders = filterOrderByStatus(activeTab);
        setOrders(newOrders);
    }, [allOrders, activeTab, filterOrderByStatus]);

    // On user changed
    useEffect(() => {
        if (user) {
            fetchShopHistory();
            fetchOrders();
        }
    }, [user]);

    // On location changed
    useEffect(() => {
        if (state && state.tab) {
            setVisibleOrders(true);
            if (state?.tab === OrderTab.Processing) {
                setActiveTab(OrderTab.Processing);
            }
            if (state?.tab === OrderTab.Delivered) {
                setActiveTab(OrderTab.Delivered);
            }
            if (state?.tab === OrderTab.Cancelled) {
                setActiveTab(OrderTab.Cancelled);
            }
        } else {
            setVisibleOrders(false);
        }
    }, [state]);

    // On mounted
    useEffect(() => {
        fetchMessages();
    }, []);

    TabTitle('Dashbord - Digital Music Shopping Market Place')
    metaTagByTitle('Dashbord - Digital Music Shopping Market Place')
    metaTagByDesc('D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.')
    metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT')
    metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`)

    // Return dashboard tab
    return (
        <>
            <div className="profile-dashboard-tab">
                <hr className="divider" />
                {visibleOrders ? (
                    <div className="orders-wrapper">
                        <div className="orders-tab">
                            {ordersByStatus.map(({ status, count }, index) => (
                                <div
                                    key={index}
                                    className={classnames('order-tab', { active: status === activeTab })}
                                    onClick={() => handleTabClick(status as OrderTab)}
                                >
                                    <span className="text-body2 order-status">{status}</span>
                                    <span className="text-body2 order-count">{count}</span>
                                </div>
                            ))}
                        </div>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/*  @ts-ignore*/}
                        <PerfectScrollbar className="orders">
                            {orders && orders.length > 0 ? (
                                orders.map(({ _id, totalPrice, createdAt, orderItems }) => (
                                    <div key={_id} className="order">
                                        <div className="order-delete">
                                            <IconButton icon="remove" />
                                        </div>
                                        <div className="text-heading4 text--lime order-total-price">${totalPrice}</div>
                                        <div className="order-code">
                                            <span className="text-body2">Order Code:</span>
                                            <span className="text-body2">12345678</span>
                                        </div>
                                        <div className="text-body2 order-date">{moment(createdAt).format('DD MMM YYYY')}</div>
                                        <div className="order-items">
                                            {orderItems
                                                ?.filter((_, index) => index < 4)
                                                .map(({ id, thumbnail }) => (
                                                    <img key={id} src={`${REACT_APP_API_ASSET_SERVER}/${thumbnail.fieldname}/${thumbnail.filename}`} alt={thumbnail.filename} />
                                                ))}
                                            {orderItems && orderItems?.length > 4 && (
                                                <span className="text-body2">+ {orderItems?.length - 4}</span>
                                            )}
                                        </div>
                                        <div className="order-next">
                                            <IconButton icon="arrow-right" onClick={() => handleOpenOrderDetailModal(_id as string)} />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="no-orders">
                                    <img src="/images/empty/order.svg" alt="no-order" />
                                </div>
                            )}
                        </PerfectScrollbar>
                    </div>
                ) : (
                    <>
                        <AnimationOnScroll animation="animate__fadeIn" isSubElement delay={1} className="my-orders-wrapper">
                            <p className="text-heading4 my-orders-title">My Orders</p>
                            <div className="my-orders">
                                {ordersByStatus.map(({ status, icon, count }, index) => (
                                    <div key={index} className="my-order" onClick={() => handleClickOrderByStatus(status as OrderTab)}>
                                        <div className="order-icon">
                                            <Icon name={icon as IIcon} />
                                        </div>
                                        <span className="text-body1 order-status">{status}</span>
                                        <span className="text-body1 text--lime order-count">{count}</span>
                                    </div>
                                ))}
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animation="animate__fadeIn" isSubElement delay={2} className="recent-messages">
                            <p className="text-heading4 recent-message-title">Messages</p>
                            {loading ? (
                                <Flex justifyContent="center" alignItems="center" height={200}>
                                    <Spinner color="#00Ff00" size="xl" />
                                </Flex>
                            ) : (
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                <PerfectScrollbar className="messages">
                                    {messages?.map((message) => (
                                        <div key={message.id} className="message">
                                            <div className="message-content">
                                                <p className="text-body2">{message.content}</p>
                                                <IconButton
                                                    icon="trash-outline"
                                                    className="delete-icon"
                                                    onClick={() => handleDeleteMessage(message.id)}
                                                />
                                            </div>
                                            <div className="message-time">
                                                <hr />
                                                <p className="text-body3">{formatDate(message.updatedAt)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </PerfectScrollbar>
                            )}
                        </AnimationOnScroll>
                    </>
                )}
                {/*<div className="analytic-chart-area">*/}
                {/*  {shopHistory.length > 0 ? <Chart data={shopHistory} /> : <p className="text-body1">There is no history</p>}*/}
                {/*</div>*/}
            </div>
        </>
    );
};
