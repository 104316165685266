// Dependencies
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Flex, Spinner, useToast } from '@chakra-ui/core';

// Components
import { AnimationOnScroll, WalletCard } from '../../../../../components';

// Interfaces
import { IWallet } from '../../../../../shared/interfaces';

// Apis
import { BalanceApi, WalletApi } from "../../../../../apis";

// Styles
import './styles.scss';
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../../../../utils/generaltittlefunction';
import { BalanceCard, IBalanceType } from '../../../../../components/BalanceCard';
import { IBalances } from '../../../../../shared/interfaces/balance.interface';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances, getUser } from '../../../../../store/selectors';
import { setBalances } from '../../../../../store/actions';

// Export wallet tab
export const WalletTab: FC = () => {
  // States
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [balance, setBalance] = useState<IBalances>({});

  // Get user from store
  const user = useSelector(getUser);

  const balances = useSelector(getBalances)
  // Dispatch initializer
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);

  // Get toast from hook
  const toast = useToast();

  // Fetch wallets
  const fetchWallets = useCallback(() => {
    setLoading(true);
    WalletApi.readAll()
      .then((res) => {
        setWallets(res.wallets);
        setLoading(false);
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
  }, [toast]);

  // On mounted
  useEffect(() => {
    if (user?.id) {
      fetchWallets();
      setLoading(true);
      BalanceApi.getBalance({
        options: {
          id: user?.id
        }
      })
        .then((res: any) => {
          dispatch(setBalances(res.balance));
          setLoading(false);
        })
        .catch((err: any) => {
          dispatch(setBalances({}));
          setLoading(false);
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [user?.id]);


  TabTitle('Wallet - Digital Music Shopping Market Place')
  metaTagByTitle('Wallet - Digital Music Shopping Market Place')
  metaTagByDesc('D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.')
  metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT')
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`)


  // Return wallet tab
  return (
    <>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height={200}>
          <Spinner color="#00Ff00" size="xl" />
        </Flex>
      ) : (
        <div className="wallets-tab">
          <div className="wallets-section">
            <h2 className="section-title text-heading3">Wallet</h2>
            <div className="cards-container">
              {wallets.map((wallet: IWallet, index) => (
                <WalletCard key={wallet._id} wallet={wallet} isDefault={wallet.default} />
              ))}
              <WalletCard />
            </div>
          </div>
          <div className="total-balance-section">
            <h2 className="section-title text-heading3">Total Balance</h2>
            <div className="cards-container">
              {balances.map((balance: any, index) => (
                <BalanceCard
                  key={index}
                  balance={balance}
                  isTest={index === balances.length - 1}
                // hasSetup={index !== 0}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
