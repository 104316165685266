// Dependencies
import React from 'react';

// Styles
import './styles.scss';

// Icons
import { ReactComponent as Apple } from '../../assets/icons/apple.svg';
import { ReactComponent as ArrowCircle } from '../../assets/icons/arrow-circle.svg';
import { ReactComponent as ArrowRectangle } from '../../assets/icons/arrow-rectangle.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowDropDown } from '../../assets/icons/arrow-drop-down.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as Attach } from '../../assets/icons/attach.svg';
import { ReactComponent as BitCoin } from '../../assets/icons/bit-coin.svg';
import { ReactComponent as BxCoin } from '../../assets/icons/bx-coin.svg';
import { ReactComponent as Casper } from '../../assets/icons/casper.svg';
import { ReactComponent as CasperCoin } from '../../assets/icons/casper-coin.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as CreateAccount } from '../../assets/icons/create-account.svg';
import { ReactComponent as CreditCard } from '../../assets/icons/credit-card.svg';
import { ReactComponent as Crypto } from '../../assets/icons/crypto.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as Deposit } from '../../assets/icons/deposit.svg';
import { ReactComponent as DesktopMac } from '../../assets/icons/desktop-mac.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Email } from '../../assets/icons/email.svg';
import { ReactComponent as Ethereum } from '../../assets/icons/ethereum.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeCircle } from '../../assets/icons/eye-circle.svg';
import { ReactComponent as EyeOff } from '../../assets/icons/eye-off.svg';
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as Google } from '../../assets/icons/google.svg';
import { ReactComponent as HandUp } from '../../assets/icons/hand-up.svg';
import { ReactComponent as HandDown } from '../../assets/icons/hand-down.svg';
import { ReactComponent as Heart } from '../../assets/icons/heart.svg';
import { ReactComponent as HeartFill } from '../../assets/icons/heart-fill.svg';
import { ReactComponent as InformationCircle } from '../../assets/icons/information-circle.svg';
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as MobileMenu } from '../../assets/icons/mobile-menu.svg';
import { ReactComponent as NewspaperSharp } from '../../assets/icons/newspaper-sharp.svg';
import { ReactComponent as Pause } from '../../assets/icons/pause.svg';
import { ReactComponent as Payment } from '../../assets/icons/payment.svg';
import { ReactComponent as Paypal } from '../../assets/icons/paypal.svg';
import { ReactComponent as Person } from '../../assets/icons/person.svg';
import { ReactComponent as PDF } from '../../assets/icons/pdf.svg';
import { ReactComponent as Play } from '../../assets/icons/play.svg';
import { ReactComponent as PlayOutline } from '../../assets/icons/play-outline.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Print } from '../../assets/icons/print.svg';
import { ReactComponent as Processing } from '../../assets/icons/processing.svg';
import { ReactComponent as QR } from '../../assets/icons/qr.svg';
import { ReactComponent as Refresh } from '../../assets/icons/refresh.svg';
import { ReactComponent as Remove } from '../../assets/icons/remove.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import { ReactComponent as Share } from '../../assets/icons/share.svg';
import { ReactComponent as ShareActive } from '../../assets/icons/share-active.svg';
import { ReactComponent as ShoppingBag } from '../../assets/icons/shopping-bag.svg';
import { ReactComponent as ShoppingBagCheck } from '../../assets/icons/shopping-bag-check.svg';
import { ReactComponent as ShoppingBagX } from '../../assets/icons/shopping-bag-x.svg';
import { ReactComponent as Spotify } from '../../assets/icons/spotify.svg';
import { ReactComponent as TrashOutline } from '../../assets/icons/trash-outline.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as USDCCoin } from '../../assets/icons/usdc-coin.svg';
import { ReactComponent as USDTCoin } from '../../assets/icons/usdt-coin.svg';
import { ReactComponent as VideoPause } from '../../assets/icons/video-pause.svg';
import { ReactComponent as VideoPlay } from '../../assets/icons/video-play.svg';
import { ReactComponent as WithDraw } from '../../assets/icons/withdraw.svg';
import { ReactComponent as Wave } from '../../assets/icons/wave.svg';
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg';
import { ReactComponent as WalletConnect } from '../../assets/icons/wallet-connect.svg';
import { ReactComponent as CopyClipboard } from '../../assets/icons/copy-clipboard.svg';
import { ReactComponent as metamask } from '../../assets/icons/metamask.svg';
import { ReactComponent as PenIcon } from '../../assets/icons/pen.svg';
import { ReactComponent as GiftIcon } from '../../assets/icons/gift.svg';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';
import { ReactComponent as FingerPrintIcon } from '../../assets/icons/fingerPrint.svg';
import { ReactComponent as ThankYou } from '../../assets/icons/thankyou.svg';
import { ReactComponent as StarCheck } from '../../assets/icons/star-check.svg';
import { ReactComponent as Discord } from '../../assets/icons/discord.svg';
import { ReactComponent as Earth } from '../../assets/icons/earth.svg';
import { ReactComponent as BlueTooth } from '../../assets/icons/bluetooth.svg';
import { ReactComponent as Wallet } from '../../assets/icons/wallet.svg';
import { ReactComponent as LiteCoin } from '../../assets/icons/litecoin.svg';
import { ReactComponent as TrustWallet } from '../../assets/icons/trust-wallet.svg';
import { ReactComponent as Swap } from '../../assets/icons/swap.svg';
import { ReactComponent as DollarCircle } from '../../assets/icons/dollar-circle.svg';
import { ReactComponent as EyeClose } from '../../assets/icons/eye-password-hide-svgrepo-com.svg';

// Type
export type IIcon =
    | 'apple'
    | 'arrow-circle'
    | 'arrow-down'
    | 'arrow-drop-down'
    | 'arrow-left'
    | 'arrow-right'
    | 'arrow-rectangle'
    | 'arrow-up'
    | 'attach'
    | 'bit-coin'
    | 'bx-coin'
    | 'casper'
    | 'casper-coin'
    | 'check'
    | 'check-circle'
    | 'copy'
    | 'create-account'
    | 'credit-card'
    | 'crypto'
    | 'dashboard'
    | 'deposit'
    | 'desktop-mac'
    | 'edit'
    | 'email'
    | 'ethereum'
    | 'eye'
    | 'eye-circle'
    | 'eye-off'
    | 'facebook'
    | 'filter'
    | 'google'
    | 'hand-up'
    | 'hand-down'
    | 'heart'
    | 'heart-fill'
    | 'information-circle'
    | 'instagram'
    | 'logo'
    | 'menu'
    | 'mobile-menu'
    | 'newspaper-sharp'
    | 'pause'
    | 'payment'
    | 'paypal'
    | 'person'
    | 'pdf'
    | 'play'
    | 'play-outline'
    | 'plus'
    | 'print'
    | 'processing'
    | 'qr'
    | 'refresh'
    | 'remove'
    | 'search'
    | 'settings'
    | 'share'
    | 'share-active'
    | 'shopping-bag'
    | 'shopping-bag-check'
    | 'shopping-bag-x'
    | 'spotify'
    | 'trash-outline'
    | 'twitter'
    | 'usdc-coin'
    | 'usdt-coin'
    | 'video-pause'
    | 'video-play'
    | 'wave'
    | 'withdraw'
    | 'youtube'
    | 'walletConnect'
    | 'copyClipboard'
    | 'pen-icon'
    | 'gift-icon'
    | 'cart-icon'
    | 'finger-print'
    | 'thankyou'
    | 'metamask'
    | 'star-check'
    | 'discord'
    | 'earth'
    | 'bluetooth'
    | 'wallet'
    | 'lite-coin'
    | 'trust-wallet'
    | 'swap'
    | 'dollar-circle'
    | 'eye'
    | 'eye-slash'

interface IconProps extends Partial<SVGElement> {
    name: IIcon;
    color?: string;
    width?: string | number;
    height?: string | number;
    fill?: string | number;
    onClick?: () => void;
}

// Icon map
const iconsMap: Record<IIcon, any> = {
    apple: Apple,
    'arrow-circle': ArrowCircle,
    'arrow-down': ArrowDown,
    'arrow-drop-down': ArrowDropDown,
    'arrow-left': ArrowLeft,
    'arrow-right': ArrowRight,
    'arrow-rectangle': ArrowRectangle,
    'arrow-up': ArrowUp,
    'attach': Attach,
    'bit-coin': BitCoin,
    'bx-coin': BxCoin,
    'pen-icon': PenIcon,
    'gift-icon': GiftIcon,
    'cart-icon': CartIcon,
    'finger-print': FingerPrintIcon,
    casper: Casper,
    'casper-coin': CasperCoin,
    check: Check,
    'check-circle': CheckCircle,
    copy: Copy,
    'create-account': CreateAccount,
    'credit-card': CreditCard,
    dashboard: Dashboard,
    deposit: Deposit,
    'desktop-mac': DesktopMac,
    edit: Edit,
    email: Email,
    ethereum: Ethereum,
    eye: Eye,
    'eye-circle': EyeCircle,
    'eye-off': EyeOff,
    crypto: Crypto,
    facebook: Facebook,
    filter: Filter,
    google: Google,
    'hand-up': HandUp,
    'hand-down': HandDown,
    heart: Heart,
    'heart-fill': HeartFill,
    'information-circle': InformationCircle,
    instagram: Instagram,
    logo: Logo,
    menu: Menu,
    'mobile-menu': MobileMenu,
    'newspaper-sharp': NewspaperSharp,
    pause: Pause,
    payment: Payment,
    paypal: Paypal,
    person: Person,
    pdf: PDF,
    play: Play,
    'play-outline': PlayOutline,
    plus: Plus,
    print: Print,
    processing: Processing,
    qr: QR,
    refresh: Refresh,
    remove: Remove,
    search: Search,
    settings: Settings,
    share: Share,
    'share-active': ShareActive,
    'shopping-bag': ShoppingBag,
    'shopping-bag-check': ShoppingBagCheck,
    'shopping-bag-x': ShoppingBagX,
    spotify: Spotify,
    'trash-outline': TrashOutline,
    twitter: Twitter,
    'usdc-coin': USDCCoin,
    'usdt-coin': USDTCoin,
    'video-pause': VideoPause,
    'video-play': VideoPlay,
    wave: Wave,
    withdraw: WithDraw,
    youtube: Youtube,
    metamask: metamask,
    walletConnect: WalletConnect,
    copyClipboard: CopyClipboard,
    'thankyou': ThankYou,
    'star-check': StarCheck,
    discord: Discord,
    earth: Earth,
    bluetooth: BlueTooth,
    wallet: Wallet,
    'lite-coin': LiteCoin,
    'trust-wallet': TrustWallet,
    swap: Swap,
    'dollar-circle': DollarCircle,
    'eye-slash': EyeClose
};

// Export  icon
export const Icon: React.FC<IconProps> = ({ name, className, ...props }) => {
    // Get icon from icon map
    const Component = iconsMap[name];

    if (!Component) {
        return <div />;
    }

    // Return icon
    return <Component className={`d-icon ${className}`} {...props} />;
};
