// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Flex, Input, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/core';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

// Components
import { Icon, IconButton } from '../../../components';

// Constants
import { ACCESS_TOKEN_KEY, injected, REACT_APP_ENVIRONMENT, ROUTES, walletConnect } from '../../../constants';

// Store
import { getUser, getCart, getCategories } from '../../../store/selectors';
import { setUser, setCategories, addToCart } from '../../../store/actions';

// Apis
import { CategoryApi } from '../../../apis';

// Styles
import './styles.scss';

import { StorageHelper } from '../../../helpers';

import { profileLinks } from '../../../pages';
import { getUserImage } from '../../../utils';
import ConnectWalletModal from './ConnectWalletModal';
import { useWeb3React } from '@web3-react/core';
import WalletConnected from './WalletConnected';

interface ILink {
  label: string;
  pathname: string;
  subLinks?: {
    label: string;
    pathname: string;
  }[];
}

// Create header component
const Header: FC = () => {
  //Web3React
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { account, chainId, activate } = useWeb3React();
  // States
  const [visibleProfile, setVisibleProfile] = useState<boolean>(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get history from hook
  const history = useHistory();

  // Get user from store
  const user = useSelector(getUser);

  // Get cart from store
  const cart = useSelector(getCart);

  const categories = useSelector(getCategories);

  // Category fetcher
  const fetchCategory = () => {
    CategoryApi.readAll()
      .then((res) => {
        dispatch(setCategories(res.categories));
      })
      .catch((err) => console.log(err));
  };

  // Toggle mobile menu handler
  const handleToggleMobileMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setVisibleProfile(!visibleProfile);
  };

  // Toggle profile handler
  const handleToggleProfile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (user) {
      setVisibleProfile(!visibleProfile);
    } else {
      history.push(ROUTES.AUTH.SIGN_IN);
    }
  };

  // Search button click handler
  const handleSearchButtonClick = () => {
    setVisibleSearch(!visibleSearch);
  };

  // Search handler
  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const searchKey = e.currentTarget.value;
      history.push(`${ROUTES.SEARCH_RESULT}?${searchKey}`);
    }
  };

  // Cart handler
  const handleCart = () => {
    history.push(ROUTES.CART);
  };

  // Logout handler
  const handleLogout = () => {
    dispatch(setUser(null));
    dispatch(addToCart(null));
    StorageHelper.removeItem(ACCESS_TOKEN_KEY);
    history.push(ROUTES.HOME);
  };

  // Get nav links from categories
  const navLinks = useMemo(() => {
    const links: ILink[] = categories
      .filter((cat) => cat.visibleInNav)
      .map(({ id, name, subCategories }) => ({
        label: name,
        pathname: `${ROUTES.PRODUCTS.BY_CATEGORY}?category=${id as string}`,
        subLinks: subCategories?.map((item) => ({
          label: item,
          pathname: `${ROUTES.PRODUCTS.BY_CATEGORY.replace(':categoryId', id as string)}`
        }))
      }));

    links.push(
      { label: 'Events', pathname: ROUTES.EVENTS.INDEX },
      { label: 'Artist', pathname: ROUTES.ARTIST.LIST },
      { label: 'Shop', pathname: ROUTES.SHOP.INDEX },
      { label: 'Us', pathname: ROUTES.US }
    );

    return links;
  }, [categories]);

  // On mounted
  useEffect(() => {
    window.onclick = () => {
      setVisibleProfile(false);
    };

    fetchCategory();

    return () => {
      window.onclick = null;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const provider = StorageHelper.getItem('provider');
    const connectors = {
      walletConnect: walletConnect,
      injected: injected
    } as any;
    if (provider) {
      activate(connectors[provider]);
    }
  }, [activate]);

  useEffect(() => {
    if (account) {
      let updateUser = {
        ...user,
        accountWallet: account
      };
      dispatch(setUser(updateUser as any));
    }
  }, [account, dispatch, user]);

  // Return header component
  return (
    <header className="d-header">
      <div className="content">
        <div onClick={handleToggleMobileMenu} className={classnames('menu-icon', { active: visibleProfile })}>
          <span />
          <span />
        </div>
        <Link to={ROUTES.HOME} className="logo">
          <img src={REACT_APP_ENVIRONMENT === 'demo' ? '/images/demo-logo.png' : '/images/logo.png'} alt="logo" />
        </Link>
        <div className="nav-links">
          {navLinks.map(({ label, pathname, subLinks }) =>
            subLinks && subLinks.length > 0 ? (
              <Menu key={label}>
                <MenuButton className="dropdown-button">{label}</MenuButton>
                <MenuList className="dropdown-menu">
                  {subLinks.map((subLink) => (
                    <MenuItem key={subLink.label} className="sub-category-item text-body1">
                      <Link to={subLink.pathname}>{subLink.label}</Link>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            ) : (
              <Link key={pathname} to={pathname}>
                {label}
              </Link>
            )
          )}
          <div className="actions">
            <div className={classnames('search-bar', { visible: visibleSearch })}>
              <div className="search-input-wrapper">
                <div className="search-input">
                  <Icon name="play" />
                  <Input placeholder="Search." onKeyDown={handleSearch} />
                </div>
              </div>
              <IconButton icon="search" onClick={handleSearchButtonClick} style={{ width: 20 }} />
            </div>
            <span
              className={classnames('badge', {
                active: cart?.products?.length
              })}
            >
              <span className="badge-text">{cart && cart.products?.length > 0 && cart?.products?.length}</span>
              <IconButton icon="shopping-bag" onClick={handleCart} style={{ width: 20 }} />
            </span>
            <IconButton icon="person" onClick={handleToggleProfile} style={{ width: 20 }} />
          </div>
        </div>
        <span
          className={classnames('badge badge--mobile', {
            active: cart?.products?.length
          })}
        >
          <span className="badge-text">{cart && cart?.products?.length > 0 && cart?.products.length}</span>
          <IconButton icon="shopping-bag" onClick={handleCart} style={{ width: 20 }} />
        </span>
      </div>
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        className={classnames('profile', { visible: visibleProfile })}
      >
        <div className="toolbar">
          <div className="mobile-menu">
            {navLinks.map(({ label, pathname, subLinks }) =>
              subLinks && subLinks.length ? (
                <Menu key={label}>
                  <MenuButton className="dropdown-button">{label}</MenuButton>
                  <MenuList className="dropdown-menu">
                    {subLinks.map((subLink) => (
                      <MenuItem key={subLink.label} className="sub-category-item text-body1">
                        <Link to={subLink.pathname}>{subLink.label}</Link>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              ) : (
                <Link key={pathname} to={pathname}>
                  {label}
                </Link>
              )
            )}
            {!user && <IconButton icon="person" onClick={handleToggleProfile} />}
          </div>
          <div className={classnames('search-bar', { visible: visibleSearch })}>
            <div className="search-input-wrapper">
              <div className="search-input">
                <Icon name="play" />
                <Input placeholder="Search." onKeyDown={handleSearch} />
              </div>
            </div>
            <IconButton icon="search" onClick={handleSearchButtonClick} />
          </div>
        </div>
        {user && (
          <div className="information">
            <div className="user">
              <div className="avatar">
                <img src={user?.avatar ? getUserImage(user) : '/images/avatar.png'} alt="avatar" />
              </div>
              <span className="name">
                {user?.name?.split(' ').map((name, index) => (
                  <React.Fragment key={index}>
                    {name}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </div>
            {/* {!((account && chainId) || (user.accountWallet && user.chainId)) ? (
              <Button className="d-outlined-button connect-wallet" onClick={onOpen} fontWeight={700}>
                Connect wallet
              </Button>
            ) : (
              <WalletConnected account={account || user.accountWallet} chainId={chainId || user.chainId} />
            )}
            {isOpen && <ConnectWalletModal userData={user} onClose={onClose} isOpen={isOpen} />} */}
            <div className="navs">
              {profileLinks.map(({ path, icon, name }) => (
                <NavLink key={path} exact className="nav-link" activeClassName="nav-link--active" to={path}>
                  <Icon name={icon} />
                  <span>{name}</span>
                </NavLink>
              ))}
            </div>
            <Flex justifyContent="flex-end">
              <Button className="d-outlined-button  logout-button" onClick={handleLogout}>
                Log Out
              </Button>
            </Flex>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
