// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { Tab, TabPanel, Tabs, useToast } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';

// Components
import { Icon, Select, Alert, CartButton, Pagination, AnimationOnScroll, ProductCard } from '../../../components';


// Constants
import { ROUTES } from '../../../constants';

// Apis
import { ArtistApi, GalleryApi, ProductApi, UserApi } from '../../../apis';

// Interfaces
import { IArtist, IProduct, ISortOrder } from '../../../shared/interfaces';
// Styles
import './styles.scss';
import { copyTextToClipboard } from '../../../utils';
import { REACT_APP_API_ASSET_SERVER } from '../../../constants';
import { CURRENCY } from '../../../shared/enums';
import { ProductModel } from '../../../shared/models';
import { IGallery } from '../../../shared/interfaces/gallery.interface';

// Constant
const CntPerPage = 15;


// Export gallery detail page
export const GalleryDetailPage: FC = () => {
  // States
  const [gallery, setGallery] = useState<IGallery>();
  const [selectValue, setSelectValue] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<ISortOrder>(ISortOrder.ASC);


  // Get id from hook
  const { galleryId } = useParams<{ galleryId: string }>();

  // Get history from hook
  const history = useHistory();

  // Get toast from hook
  const toast = useToast();

  // useEffect(() => {
  //   if (artist) {
  //     fetchProduct();
  //   }
  // }, [artist, pageNumber]);

  // Fetch products
  const fetchProducts = () => {
    ProductApi.readAll({
      query: {
        galleryId
      },
      options: {
        limit: CntPerPage,
        skip: (pageNumber - 1) * CntPerPage,
        sort: {
          price: sortOrder
        }
      }
    })
      .then((res) => {
        const { products, pagination } = res;
        setProducts(products.map((product: IProduct) => new ProductModel(product)));
        setTotalCnt(pagination.total);
      })
      .catch((err) => {
        console.log("🚀 ~ file: index.tsx:91 ~ fetchProducts ~ err:", err)
      });
  };



  // On mounted
  useEffect(() => {
    if (galleryId) fetchProducts();
  }, [galleryId]);

  // On mounted
  useEffect(() => {
    GalleryApi.readById(galleryId)
      .then((res) => {
        setGallery(res.gallery)
      })
  }, []);



  // Copy Address to Clipboard


  // // Go to detail page handler
  // const handleGoToDetail = (galleryId: string) => () => {
  //   history.push(`/artists/${id}/gallery/${galleryId}/detail`);
  // };

  // Return artist detail page
  return (
    <div className="artist-detail-page">
      <div className="banner-wrapper">
        <div className="banner-container">
          <Carousel
            autoPlay={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            infiniteLoop={true}

          >
            {new Array(3).fill(1).map((item, index) => (
              <img key={index} src="/images/artists/banner.png" alt="banner" />
            ))}
          </Carousel>
        </div>
        <div className="artist-image-wrapper">
          <div className="artist-image-container">
            <img src={gallery?.thumbnail} alt="artist_thumbnail" />
          </div>
        </div>
      </div>
      <div className="artist-detail-container">
        <div className="artist-info text--white">
          <div className="artist-header-container">
            <h1 className="text-heading2">{gallery?.name}</h1>
            {/* <div className="icons-container">
              <a href={artist?.discord ? artist?.discord : 'https://discord.com/'}><Icon name="discord" /></a>
              <a href={artist?.spotify ? artist?.spotify : 'https://open.spotify.com/'}><Icon name="spotify" /></a>
              <a href={artist?.twitter ? artist?.twitter : 'https://twitter.com/?lang=en'}><Icon name="twitter" /></a>
              <a href={artist?.instagram ? artist?.instagram : 'https://www.instagram.com/accounts/login/'}><Icon name="instagram" /></a>
              <a href={artist?.website}><Icon name="earth" /></a>
              <span className="bluetooth-icon-container" onClick={() => copyAddress()}>
                <Icon name="bluetooth" />
              </span>
              <Select
                options={walletOption}
                value={selectValue}
                onChange={(value) => setSelectValue(value)}
                className="select"
              />
            </div> */}
          </div>
          <p className="artist-joined-at text-body4">   {moment(gallery?.createdAt).format("DD MMMM YYYY")}</p>
          <p className="artist-description text-body3">
            {gallery?.description}
          </p>
        </div>
      </div>

      <div className="content">
        <div className="products">
          {products.length > 0 &&
            products.map((product) => (
              <AnimationOnScroll key={product.id} animation="animate__fadeIn" isSubElement>
                <ProductCard key={product.id} product={product} />
              </AnimationOnScroll>
            ))}
        </div>
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
