// Dependencies
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import { AudioPlayer, CartButton } from '../../../../components';

// Utils
import { getRandomGradientClass } from '../../../../utils';

// Constants
import { REACT_APP_API_ASSET_SERVER, ROUTES} from '../../../../constants';

// Styles
import './styles.scss';

// Interfaces
import { CURRENCY } from '../../../../shared/enums';
import { ProductModel } from '../../../../shared/models';

interface ISongProps {
  product: ProductModel;
}

// Export song component
export const Song: FC<ISongProps> = ({
  product: {
    id,
    name,
    preview,
    categoryNames,
    price,
    currency,
    thumbnail,
    statement,
    sign,
    getThumbnailUrl,
    getPreviewSongUrl
  }
}) => {
  return (
    <div className={`featured-song ${getRandomGradientClass()}`}>
      <div className={`product-summary ${getRandomGradientClass()}`}>
        <div className="backdrop" />
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${id}`}>
          <h2 className="text-heading2 text--lime product-name">{name}</h2>
        </Link>
        <hr />
        {preview?.filename && <AudioPlayer src={getPreviewSongUrl} />}
        <span className="text-body1 product-genre">{categoryNames}</span>
        <CartButton productId={id as string} productPrice={price as number} productCurrency={currency as CURRENCY} />
        {sign && <img src={`${REACT_APP_API_ASSET_SERVER}/${sign.fieldname}/${sign.filename}`} className="mobile-sign" alt="sign" />}
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${id}`}>
          <img className="product-image" src={getThumbnailUrl} alt={thumbnail?.filename} />
        </Link>
      </div>
      <div className="product-description">
        <h3 className="text-heading3">Artist's Statement.</h3>
        <hr />
        <div className="description">
          <p className="text-body1 statement" dangerouslySetInnerHTML={{ __html: statement || '' }} />
          {sign && <img src={`${REACT_APP_API_ASSET_SERVER}/${sign.fieldname}/${sign.filename}`} className="sign" alt="sign" />}
        </div>
      </div>
    </div>
  );
};
