// Dependencies
import React, { FC } from 'react';


// Styles
import './styles.scss';
import { Icon } from '../../../../../components';

// Interfaces
interface FAQDetailHeaderProps {
  params: any;
}

// Export FAQ page
export const FAQDetailHeaderPage: FC<FAQDetailHeaderProps> = ({params}) => {
    return (
        <div className='faq-detail-page-header'>
            <div className='faq-title'>
                <h2 className='text-body1'>D-Music Help Center   / FAQ / {params}</h2>
            </div>
            <div className="search-input-wrapper">
                <div className="search-input">
                    <Icon name="search" />
                    <input placeholder="Discover your issue..." />
                </div>
            </div>
        </div>
    );
};
