// Dependencies
import React, { FC } from 'react';

// Components
import { AnimationOnScroll, AudioPlayer, CartButton, IconButton } from '../../../components';
import classnames from 'classnames';
import { getRandomGradientClass, plainToHtml } from '../../../utils';

// Share
import { CURRENCY } from '../../../shared/enums';
import { ProductModel } from '../../../shared/models';

// Styles
import './styles.scss';

// Props
interface ProductDetailSongPageProps {
    product: ProductModel;
    handleToggleFavorite?:  () => void;
    handleNext?: () => void
    isFavorite?: boolean;
}

// Render
export const ProductDetailSongPage: FC<ProductDetailSongPageProps> = ({ product, handleToggleFavorite, isFavorite, handleNext }) => {
    return (
        <div className="product-detail-song-page">
            <div className="product-song-content">
                <div className="ticket-content">
                    <AnimationOnScroll animation="animate__backInRight" delay={1} className="ticket-song-left">
                        <div className="ticket-song-content">
                            <div className="ticket-song-header">
                                <h2 className="text-heading2 text--lime song-title">{product.name}</h2>
                                <div className="song-type">
                                    <span className="text-body1 text-bold text--cyan">Synthwave</span>
                                    <span className="text-body1 text-bold text--cyan">Instrumental</span>
                                </div>
                            </div>
                            <div className="audio-wrapper">
                                <AudioPlayer src={product.getPreviewSongUrl}/>
                            </div>
                            <div className="cart">
                                <CartButton
                                    color="lime"
                                    productId={product.id as string}
                                    productPrice={product.price as number}
                                    productCurrency={product.currency as CURRENCY}
                                />
                                <IconButton
                                    icon="heart"
                                    className={classnames('favorite-button', {
                                        'favorite-button--active': isFavorite
                                    })}
                                    onClick={handleToggleFavorite}
                                />
                                <IconButton icon="share"/>
                            </div>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animation="animate__backInRight" delay={1.5} className="ticket-song">
                            <div className={`ticket-image ${getRandomGradientClass()}`}>
                                <div className="ticket-image-content">
                                     <img src={product?.getThumbnailUrl}/> 
                                     </div>
                            </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll className="next-btn-wrapper" animation="animate__slideInRight" delay={2.5}>
                        <img src={product?.nextProductAvatarUrl} alt="thumbnail-mask"/>
                        <IconButton icon="arrow-rectangle" className="next-button" onClick={handleNext}/>
                    </AnimationOnScroll>
                </div>
                <div className="ticket-content x">
                    <AnimationOnScroll className="ticket-info description-content" animation="animate__backInRight" delay={2}>
                        <div className="description">
                            <span className="text-heading3 text--white">Description.</span>
                            <div
                                className="text-body1 text--white description-text"
                                dangerouslySetInnerHTML={{__html: plainToHtml(product?.description || '')}}
                            />
                        </div>
                    </AnimationOnScroll>
                </div>
            </div>
        </div>
    );
};
