// Dependencies
import React, { FC } from 'react';
import { Button, Input, FormControl, FormHelperText, useToast } from '@chakra-ui/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// Components
import { AnimationOnScroll, Icon } from '../../../components';

// Apis
import { AuthApi } from '../../../apis';

// Constants
import { ROUTES } from '../../../constants';

// Styles
import './styles.scss';
import { Alert } from '../../../components';

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Email is not valid').required('Email is required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
    .matches(/\d/, 'Password should contain at least one number.')
    .min(8, 'Password should contain at least 8 characters.')
});

// Interfaces
interface ISignUpValues {
  name: string;
  email: string;
  username: string;
  password: string;
}

// Export sign-up page
export const SignUpPage: FC = () => {
  // Get history from hook
  const history = useHistory();

  // Get toast from hook
  const toast = useToast();

  // Submit handler
  const handleSubmit = (values: ISignUpValues, { setSubmitting }: FormikHelpers<ISignUpValues>) => {
    AuthApi.register(values)
      .then(() => {
        setSubmitting(false);
        console.log("🚀 ~ file: index.tsx:56 ~ .then ~ ROUTES.AUTH.CONFIRM_EMAIL:", ROUTES.AUTH.CONFIRM_EMAIL)
        history.push(ROUTES.AUTH.CONFIRM_EMAIL);
      })
      .catch((err) => {
        const message = err.msg || 'Something went wrong';
        setSubmitting(false);
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={message} color="red" onClose={onClose} />
        });
      });
  };

  // Login handler
  const handleLogin = () => {
    history.push(ROUTES.AUTH.SIGN_IN);
  };

  // Return sign-up page
  return (
    <div className="sign-up-page">
      <AnimationOnScroll animation="animate__bounce" delay={4}>
        <div className="page-title auth-page-title">
          <h2 className="text-heading2">Create Account.</h2>
        </div>
      </AnimationOnScroll>
      <div className="content">
        <Formik
          initialValues={{ name: '', email: '', username: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <AnimationOnScroll animation="animate__backInUp" delay={1}>
              <Form className="form" onSubmit={handleSubmit}>
                <FormControl className="d-form-control" isInvalid={Boolean(errors.name && touched.name)}>
                  <Input
                    className="d-form-input"
                    placeholder="Enter your full name"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText className="d-form-helper-text">
                    {errors.name && touched.name && String(errors.name)}
                  </FormHelperText>
                </FormControl>
                <FormControl className="d-form-control" isInvalid={Boolean(errors.email && touched.email)}>
                  <Input
                    className="d-form-input"
                    placeholder="Enter your email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText className="d-form-helper-text">
                    {errors.email && touched.email && String(errors.email)}
                  </FormHelperText>
                </FormControl>
                <FormControl className="d-form-control" isInvalid={Boolean(errors.username && touched.username)}>
                  <Input
                    className="d-form-input"
                    placeholder="Enter your username"
                    name="username"
                    type="text"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText className="d-form-helper-text">
                    {errors.username && touched.username && String(errors.username)}
                  </FormHelperText>
                </FormControl>
                <FormControl className="d-form-control" isInvalid={Boolean(errors.password && touched.password)}>
                  <Input
                    className="d-form-input"
                    placeholder="Choose a Password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText className="d-form-helper-text">
                    {errors.password && touched.password && String(errors.password)}
                  </FormHelperText>
                </FormControl>
                <Button
                  className="d-button d-button--full-width account-button"
                  type="submit"
                  leftIcon={() => <Icon name="create-account" />}
                  isLoading={isSubmitting}
                >
                  Create account
                </Button>
                <p className="text-body3 text-body3--lg agree-text">
                  By clicking the form, you agree to our <Link to={ROUTES.TERMS_CONDITIONS}>Terms and Conditions</Link>{' '}
                  and <Link to={ROUTES.PRIVACY_STATEMENT}>Privacy and Cookies Policy.</Link>
                </p>
              </Form>
            </AnimationOnScroll>
          )}
        </Formik>
        <div className="dividerContainer">
          <div className="divider" />
          <div className="orContainer">
            <span className="text-body2 text--black text-bold text-body3--lg ">Or</span>
          </div>
          <div className="divider" />
        </div>
        <div className="action">
          <AnimationOnScroll animation="animate__fadeIn" delay={2}>
            <span className="text-heading5 text-body3--lg">Already have an account?</span>
          </AnimationOnScroll>
          <AnimationOnScroll animation="animate__fadeIn" delay={2}>
            <Button className="d-button d-button--full-width account-button" onClick={handleLogin}>
              Login
            </Button>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};
