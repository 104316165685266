// Dependencies
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, useToast } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

// Components
import { Alert, IconButton } from '../../../components';

// Constants
import { ROUTES } from '../../../constants';

// Apis
import { CartApi } from '../../../apis';

// Store
import { getUser } from '../../../store/selectors';
import { addToCart } from '../../../store/actions';

import { ProductModel } from '../../../shared/models';

// Styles
import './styles.scss';

interface IProductProps {
  product: ProductModel;
}

// Create product component
const Product: React.FC<IProductProps> = ({ product }) => {
  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get toast from hook
  const toast = useToast();

  // Get user from store
  const user = useSelector(getUser);

  // Remove handler
  const handleRemove = (product: ProductModel) => {
    if (user) {
      CartApi.removeFromCart({
        productId: product.id as string,
        fingerprint: user.id,
        userId: user.id
      }).then((res) => {
        dispatch(addToCart(res.cart));
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={`${product.name} was removed from your cart!`} onClose={onClose} />
        });
      });
    }
  };

  // Return product component
  return (
    <div className="cart-product">
      <div className="content-1">
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${product.id}`}>
          <Avatar className="product-image" src={product.getAvatarUrl} />
        </Link>
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${product.id}`}>
          <span className="text-heading4 product-name">{product.name}</span>
        </Link>
      </div>
      <div className="content-2">
        <span className="text-heading4 product-price">${product.price}</span>
        <IconButton icon="remove" className="remove-button" onClick={() => handleRemove(product)} />
      </div>
    </div>
  );
};

// Export product component
export default Product;
