import Http from './http';

import { IQuery } from '../shared/interfaces';

export const readAll = () => {
  return Http.get('/wallet');
};

export const read = (id: string) => {
  return Http.get(`/wallet/${id}`);
};

export const updateWallet = (id: string, body: any) => {
  return Http.patch(`/wallet/edit/${id}`, body);
};

