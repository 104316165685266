// Dependencies
import React, { FC, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Layout
import { MainLayout, HelpCenterLayout } from '../layout';

// Helpers
import { StorageHelper } from '../helpers';

// Constants
import { ACCESS_TOKEN_KEY, AUTH_ROUTES, MAIN_ROUTES, IRoute, HELP_CENTER_ROUTES } from '../constants';

// Apis
import { AuthApi, BalanceApi, CartApi, TransactionApi } from '../apis';

// Store
import { setUser, addToCart, setBalances, setTransactions } from '../store/actions';
import { getUser } from '../store/selectors';
import { UserModel } from '../shared/models';
import { CartModel } from '../shared/models/cart.model';

// Create child routes
const childRoutes = (routeType: String, Layout: FC, routes: IRoute[]) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element: IRoute, index: number) => {
        const Guard = element.guard || React.Fragment;
        const ElementComponent = element.component || React.Fragment;
        if (element.path == 'confirm-email') {
          alert("ConfirmEmail")
        }
        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Layout>
                <Guard>
                  <ElementComponent {...props} />
                </Guard>
              </Layout>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Guard>
              <Component {...props} />
            </Guard>
          </Layout>
        )}
      />
    ) : null;
  });

// Create app routes
const AppRoutes: FC = () => {
  // Get user from store
  const user = useSelector(getUser);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get token from storage
  const token = StorageHelper.getItem(ACCESS_TOKEN_KEY);

  // Set user
  if (!user && token) {
    AuthApi.me()
      .then((res) => {
        const user = new UserModel(res.user);
        if (user) {
          dispatch(setUser(user));
        }
      })
      .catch((err) => console.log(err));
  }

  const setUserBalances = useCallback(() => {
    BalanceApi.getBalance({
      options: {
        id: user?.id
      }
    })
      .then((res) => {
        dispatch(setBalances(res.balance));
      })
      .catch((err) => {
        dispatch(setBalances({}));
        console.log(err);
      });
  }, [dispatch, user]);

  const setUserTransactions = useCallback(() => {
    TransactionApi.gettransactions({
      query: {
        userId: user?.id
      },
      options: {
        limit: 5
      }
    })
      .then((res) => {
        dispatch(setTransactions(res.transactions));
      })
      .catch((err) => {
        dispatch(setTransactions([]));
        console.log(err);
      });
  }, [dispatch, user]);

  const setUserCart = useCallback((userId: string) => {
    CartApi.read({
      fingerprint: userId
    })
      .then((res) => {
        dispatch(addToCart(new CartModel(res.cart)));
      })
      .catch((err) => {
        dispatch(addToCart(null));
        console.log(err);
      });
  }, [dispatch]);

  // On user changed
  useEffect(() => {
    if (user) {
      setUserBalances();
      setUserTransactions();
      setUserCart(user?.id);
    }
  }, [user, dispatch, setUserBalances, setUserTransactions, setUserCart]);

  // Return app routes
  return (
    <Router>
      <Switch>
        {childRoutes('public', MainLayout, AUTH_ROUTES)}
        {childRoutes('public', MainLayout, MAIN_ROUTES)}
        {childRoutes('public', HelpCenterLayout, HELP_CENTER_ROUTES)}
        <Redirect path="*" to={'/'} />
      </Switch>
    </Router>
  );
};

// Export app routes
export default AppRoutes;
