// Http
import Http from './http';

// Interfaces
import { IAccessToken, IApplicationStatus, IQuery } from '../shared/interfaces';

// Export user apis
export const getFavorites = () => {
  return Http.get(`/users/favorites`);
};

export const updateProfile = (id: string, body: object) => {
  return Http.patch(`/users/${id}`, body);
};

export const updatePassword = (id: string, body: object) => {
  return Http.patch(`/users/${id}/update-password`, body);
};

export const updateAvatar = (id: string, avatar: FormData) => {
  return Http.post(`/users/${id}/update-avatar`, avatar);
};

export const getMessages = (query: IQuery) => {
  return Http.get('/users/messages', query);
};

export const removeMessage = (messageId: string) => {
  return Http.delete(`/users/messages/${messageId}`);
};

export const getShopHistory = (id: string) => {
  return Http.get(`/users/${id}/shop-history`);
};

export const getKYCAccessToken = (query: IAccessToken) => {
  return Http.get(`/kyc/accessToken?userId=${query.userId}`);
};

export const getApplicationStatus = (query: IApplicationStatus) => {
  return Http.get(`/kyc/applicationStatus?applicantId=${query.applicantId}`);
};
