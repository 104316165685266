// Dependencies
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// Utils
import { convertToKebabCase } from '../../../../utils';
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../../../utils/generaltittlefunction';

// Styles
import './styles.scss';
import { IFaqCategory, IFaqQuestion } from '../../../../shared/models';
import { FaqApi } from '../../../../apis';
import { FAQDetailHeaderPage } from './Header';
import { FAQDetailLeftPage } from './LeftContent';
import { FAQDetailRightPage } from './RightContent';

// Export FAQ detail page
export const FAQDetailPage: FC = () => {
    const [categories, setCategories] = useState<IFaqCategory[]>([]);
    const [question, setQuestion] = useState<IFaqQuestion>();

    // Get params from hook
    const params = useParams<{ group: string; question: string }>();

    // Fetch categories
    const fetchCategories = () => {
        FaqApi.readCategories()
            .then((res) => {
                setCategories(res.faqCategories);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // On mounted
    useEffect(() => {
        fetchCategories();
    }, []);

    // Get group from faq
    const group = useMemo(() => {
        return categories.find(({ title }) => convertToKebabCase(title).includes(params.group));
    }, [categories, params]);

    console.log('group', group ? moment().diff(group.updatedAt,'days') : '');
    const fetchQuestion = useCallback(()=> {
        FaqApi.readQuestions()
            .then((res) => {
                if(res.faqQuestions) {
                    res.faqQuestions.map((item:any) => {
                        if( convertToKebabCase(item.category.title) === params?.group && convertToKebabCase(item.title) === params.question ) {
                            console.log('item',item);
                            setQuestion(item);
                        }
                        return item;
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },[params]);

    useEffect(() => {
        fetchQuestion();
    },[params, fetchQuestion]);
    TabTitle(` ${group?.title} - Digital Music Shopping Market Place`)
    metaTagByTitle(`${group?.title}  - Digital Music Shopping Market Place`)
    metaTagByDesc(` ${group?.questions}`)
    metaTagByKey(` ${group?.questions} Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT`)
    metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`)

    // Return FAQ detail page
    return (
        <div className="faq-detail-page">
            <FAQDetailHeaderPage params={`${group?.title} / ${params.question}`}/>
            <div className='faq-detail-page-content'>
                <FAQDetailLeftPage question={question}/>
                <FAQDetailRightPage group={group} question={question} params={params}/>
            </div>
        </div>
    );
};
