// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Avatar, Button } from '@chakra-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

// Components
import { AnimationOnScroll, Icon, IconButton } from '../../components';

// Apis
import { OrderApi } from '../../apis';

// Global constants
import { REACT_APP_API_ASSET_SERVER, ROUTES } from '../../constants';

// Interfaces
import { IOrder, OrderStatus } from '../../shared/interfaces';

// Styles
import './styles.scss';

// Export order detail page
export const OrderDetailPage: FC = () => {
  // States
  const [order, setOrder] = useState<IOrder>();

  // Get params from hook
  const params = useParams<{ id: string }>();

  // Get history from hook
  const history = useHistory();

  // Go back handler
  const handleGoBack = () => {
    history.push(ROUTES.PROFILE.DASHBOARD, { tab: order?.status });
  };

  // Get title
  const getTitle = (orderStatus?: OrderStatus, isSub = false) => {
    switch (orderStatus) {
      case OrderStatus.Created: {
        return 'Processing';
      }

      case OrderStatus.Processed: {
        return isSub ? 'Delivered details' : 'Delivered';
      }

      case OrderStatus.Cancelled: {
        return 'Cancelled.';
      }

      default: {
        return 'Processing.';
      }
    }
  };

  // On params changed
  useEffect(() => {
    if (params?.id && !order) {
      OrderApi.read(params.id)
        .then((res) => {
          console.log(res);
          setOrder(res.order);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params, order]);

  //get coin name
  const crypto: any = {
    ETH: ['ethereum', 'Ethereum'],
    CSPR: ['casper-coin', 'Casper'],
  };
  // Return order detail page
  return (
    <div className="order-detail-page">
      <AnimationOnScroll animation="animate__bounce" delay={2.5}>
        <div className="page-title">
          <h2 className="text-heading2">{getTitle(order?.status)}.</h2>
        </div>
      </AnimationOnScroll>
      <div className="content">
        <div className="lead">
          <h4 className="text-heading4">Product</h4>
          <h4 className="text-heading4">Category</h4>
          <h4 className="text-heading4">Price</h4>
        </div>
        <hr className="divider" />
        <div className="products">
          {order &&
            order?.orderItems?.map((product, index) => {
              console.log("🚀 ~ file: index.tsx:98 ~ order?.orderItems?.map ~ product:", product)
              return (
                <AnimationOnScroll key={index} animation="animate__slideInRight">
                  <div className="product" onClick={() => {
                    history.push(`/orders/${params?.id}/nft/${product?.nftDetail?.details.transactionHash}?name=${product.productName}`);
                  }}>
                    <div className="product-name">
                      <Avatar className="product-image" src={`${REACT_APP_API_ASSET_SERVER}/${product.thumbnail?.fieldname}/${product.thumbnail.filename}`} />
                      <span className="text-heading4">{product.productName}</span>
                    </div>
                    <span className="text-heading4 product-category">{product.category.map((c) => c)}</span>
                    <span className="text-heading4 product-price">
                      {product.currency}
                      {product.price}
                    </span>
                    <IconButton className="arrow-button" onClick={() => {
                      history.push(`/orders/${params?.id}/nft/${product?.nftDetail?.details.transactionHash}?name=${product.productName}`);
                    }}>
                      <Icon name="arrow-right" />
                    </IconButton>
                  </div>
                </AnimationOnScroll>
              )
            })}
        </div>
      </div>
      <AnimationOnScroll animation="animate__slideInUp" delay={1.5} isSubElement className="detail-panel">
        <h4 className="text-heading4 detail-panel-title">{getTitle(order?.status, true)}</h4>
        <div className="summary">
          {order && order?.status === OrderStatus.Created && (
            <div className="detail-steps">
              <div className="detail-step">
                <span className="text-body2">1</span>
                <div className="step-divider" />
                <span className="text-body2">2</span>
                <div className="step-divider" />
                <span className="text-body2">3</span>
              </div>
              <div className="detail-step-content">
                <p className="text-heading4 label">Profile information verification</p>
                <div className="detail-description text-body2">
                  {order.firstName ||
                    (order.lastName ? (
                      <>
                        {order.firstName || order.lastName}
                        <br />
                      </>
                    ) : order.username)}
                  {order.phoneNumber && (
                    <>
                      {order.phoneNumber}
                      <br />
                    </>
                  )}
                </div>
                <p className="text-heading4 label">Payment verification</p>
                <div className="detail-description text-body2">
                  {order?.cryptoInfo ? `Prepaid ${crypto[`${order?.cryptoInfo?.currency}`][1]} Balance` : order.paymentMethod}
                  <br />
                  <span className="crypto-item">
                    {order?.cryptoInfo && <Icon name={crypto[`${order?.cryptoInfo?.currency}`][0]} />}
                    {order?.cryptoInfo?.amount}
                  </span>
                  <span className="crypto-item">${order.totalPrice}</span>
                </div>
                <p className="text-heading4 label">Delivery verification</p>
                <div className="detail-description text-body2">
                  To <span className="customer-email">{order.email}</span>
                  <br />
                  After receiving your order in your email inbox, approve it by clicking on the button below.
                </div>
              </div>
            </div>
          )}
          {order && order?.status === OrderStatus.Processed && (
            <>
              <div className="detail-item">
                <span className="text-body1">Order code</span>
                <span className="text-body1">{params.id}</span>
              </div>
              <div className="detail-item">
                <span className="text-body1">Order date</span>
                <span className="text-body1">
                  {moment(order.createdAt).format('DD MMM YYYY')}
                  <br />
                  {moment(order.createdAt).format('hh:mm:ss (Z)')}
                </span>
              </div>
              <div className="divider" />
              <div className="detail-item">
                <span className="text-body1">Delivered to:</span>
                <span className="text-body1">
                  {order.firstName ||
                    (order.lastName && (
                      <>
                        {order.firstName || order.lastName}
                        <br />
                      </>
                    ))}
                  {order.phoneNumber && (
                    <>
                      {order.phoneNumber}
                      <br />
                    </>
                  )}
                  {order.email && <>{order.email}</>}
                </span>
              </div>
              <div className="detail-item">
                <span className="text-body1">Delivery date:</span>
                <span className="text-body1">
                  {moment(order.updatedAt).format('DD MMM YYYY')}
                  <br />
                  {moment(order.updatedAt).format('hh:mm:ss (Z)')}
                </span>
              </div>
              <div className="divider" />
              <div className="detail-item">
                <span className="text-body1">Payment method:</span>
                <span className="text-body1">
                  {order?.cryptoInfo ? `Prepaid ${crypto[`${order?.cryptoInfo?.currency}`][1]}` : order.paymentMethod}
                  <br />
                  Balance
                </span>
              </div>
              <div className="detail-item">
                <span className="text-body1">Total Amount</span>
                <span className="text-body1">
                  ${order.totalPrice?.toFixed(2)}
                  <br />
                  <span>
                    {order?.cryptoInfo && <Icon name={crypto[`${order?.cryptoInfo?.currency}`][0]} />}
                    {order?.cryptoInfo?.amount.toFixed(2)}
                  </span>
                </span>
              </div>
              <div className="detail-item">
                <span className="text-body1">Vat</span>
                <span className="text-body1">
                  ${order.vat}
                  <br />
                </span>
              </div>
            </>
          )}
          <div className="detail-actions">
            <Button className="d-outlined-button back-button" onClick={handleGoBack}>
              Back
            </Button>
          </div>
        </div>
      </AnimationOnScroll></div>
  );
};
