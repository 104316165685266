// Http
import Http from './http';

// Interfaces
import { IQuery } from '../shared/interfaces';

// Export transaction apis
export const gettransactions = (query: IQuery) => {
  return Http.get('/transaction', query);
};

// Send transaction to blockchain
export const deployWalletTransaction = (value: any) => {
  return Http.post('/transaction/deploy', { ...value });
};
