// Http
import Http from './http';

// Interfaces
import { IQuery } from '../shared/interfaces';

// Export category apis
export const readAll = (query?: IQuery) => {
  return Http.get('/categories', query);
};
