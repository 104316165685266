import React,  { FC } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../Icon';
import { ROUTES } from '../../../constants';
import casperImage from '../../../assets/images/Casper_Wordmark_White_RGB.png'
import './styles.scss';

export const MainFooter: FC = () => {
    return (
        <footer className="main-footer">
            <div className="footer-top">
                <div className="social">
                    <Link to="/">
                        <img className="footer-logo" src='/images/logo.png'/>
                    </Link>
                    <span className="text-body1 description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                    <div className="social-link-group">
                        <Link className="social-link"  to="#">
                            <Icon name="spotify" />
                        </Link>
                        <Link className="social-link" to="#">
                            <Icon name="youtube" />
                        </Link>
                        <Link className="social-link" to="#">
                            <Icon name="facebook" />
                        </Link>
                        <Link className="social-link" to="#">
                            <Icon name="instagram" />
                        </Link>
                    </div>
                </div>
                <div className="footer-category">
                    <div className="footer-category-item">
                        <Link to="#" className="text-heading4 text-bold">Marketplace</Link>
                        <Link to={ROUTES.PRODUCTS.INDEX} className="text-body1">All NFTs</Link>
                        <Link to={ROUTES.EVENTS.INDEX} className="text-body1">Event Tickets</Link>
                        <Link to={ROUTES.PRODUCTS.INDEX} className="text-body1">Categories</Link>
                    </div>
                    <div className="footer-category-item">
                        <Link to="#" className="text-heading4 text-bold">My Account</Link>
                        <Link to={ROUTES.PROFILE.INDEX} className="text-body1">Profile</Link>
                        <Link to={ROUTES.PROFILE.LIKED_PRODUCTS} className="text-body1">Favorites</Link>
                        <Link to={ROUTES.ORDERS.PREFIX} className="text-body1">My Orders</Link>
                        {/* <Link to={ROUTES.PROFILE.PAYMENT_METHODS} className="text-body1">Payment Methods</Link> */}
                        <Link to={ROUTES.PROFILE.SETTINGS} className="text-body1">Settings</Link>
                    </div>
                    <div className="footer-category-item">
                        <Link to="#" className="text-heading4 text-bold">Resources</Link>
                        <Link to={ROUTES.HELP_CENTER.INDEX} className="text-body1">Help Center</Link>
                        <Link to="#" className="text-body1">Blog</Link>
                        <Link to={ROUTES.ARTICLE.LIST} className="text-body1">Newsletter</Link>
                        <Link to="#" className="text-heading4 text-bold company">Company</Link>
                        <Link to={ROUTES.US} className="text-body1">About</Link>
                        <Link to="#" className="text-body1">Careers</Link>
                    </div>
                </div>
            </div>
            <div className="casper">
                <div className="inner-link-group">
                    <Link className="text-body1" to="#">
                        Copyright @ Dmusic.io
                    </Link>
                    <Link className="text-body1" to="#">
                        info@dmusic.io
                    </Link>
                </div>
                <div className="casper-info">
                    <span className="text-body1">Powered by</span>
                    <img src={casperImage} alt="casper" />
                </div>
                <div className="inner-link-group">
                    <Link className="text-body1" to={ROUTES.PRIVACY_STATEMENT}>
                        Privacy Statement
                    </Link>
                    <Link className="text-body1" to={ROUTES.TERMS_CONDITIONS}>
                        Terms & Conditions
                    </Link>
                </div>
            </div>
        </footer>
    );
};
