// Dependencies
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Box, Stack, Tab, TabPanel, Tabs } from '@chakra-ui/core';

import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { SCREEN_RESOLUTION } from '../../shared/enums/screen-resolution.enum';

// Styles
import './styles.scss';
import { AnimationOnScroll } from '../../components';
import { UsApi } from "../../apis";
import { IUs } from "../../shared/models";
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../utils/generaltittlefunction';

// Types
enum SlideDirection {
  Up = 'up',
  Down = 'down'
}

interface IPanelItem {
  position: 'left' | 'center' | 'right';
  width: 'half' | 'third';
  top?: number | string;
  bottom?: number | string;
  title?: ReactElement;
  description: string;
}

// Export us page
export const UsPage: FC = () => {
  // States
  const [tabId, setTabId] = useState<number>(0);
  const [oldTabId, setOldTabId] = useState<number>();
  const [slideDirection, setSlideDirection] = useState<SlideDirection>();
  const [isScrolling, setIsScrolling] = useState(false);
  const [usData, setUsData] = useState<IUs[]>([]);

  const windowSize = useWindowSize();

  const isMobile = useMemo(
    // @ts-ignore
    () => windowSize && windowSize?.resolution < SCREEN_RESOLUTION.LG,
    [windowSize]
  );

  // Tab change handler
  const handleTabChange = (id: number) => {
    if (id > tabId) {
      setSlideDirection(SlideDirection.Up);
    } else {
      setSlideDirection(SlideDirection.Down);
    }
    setOldTabId(tabId);
    setTabId(id);
    if (id === formattedUsData.length - 1) {
      document.getElementsByTagName('footer')[0].style.display = 'flex';
    } else {
      document.getElementsByTagName('footer')[0].style.display = 'none';
    }
    setTimeout(() => {
      setOldTabId(undefined);
    }, 1000);
  };

  // Mouse wheel handler
  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!isScrolling) {
      setIsScrolling(true);
      if (e.deltaY > 10 && tabId < formattedUsData.length - 1) {
        handleTabChange(tabId + 1);
      }
      if (e.deltaY < -10 && tabId > 0) {
        handleTabChange(tabId - 1);
      }
      setTimeout(() => setIsScrolling(false), 1000);
    }
  };

  useEffect(() => {
    document.getElementsByTagName('footer')[0].style.display = isMobile
      ? 'flex'
      : 'none';
  }, [isMobile]);

  // Fetch data
  const fetchData = () => {
    UsApi.readAll()
      .then((res) => {
        setUsData(res.us);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formattedUsData = useMemo<IPanelItem[][]>(() => {
    const result: any[] = [];
    let node: any[] = [];

    usData.forEach((item, index) => {
      if (index % 3 === 0) {
        node.push({
          position: 'left',
          width: 'third',
          top: '27%',
          bottom: '',
          title: item.title,
          description: item.content
        });
      } else if (index % 3 === 1) {
        node.push({
          position: 'right',
          width: 'third',
          top: '',
          bottom: '13%',
          title: item.title,
          description: item.content
        });
        result.push(node);
        node = [];
      } else {
        result.push([{
          position: 'center',
          width: 'half',
          bottom: 0,
          title: item.title,
          description: item.content
        }]);
      }
    });
    return result;
  }, [usData]);

  // On mounted
  useEffect(() => {
    fetchData();
  }, []);

  //dynamic handle title
  TabTitle('Us - Digital Music Shopping Market Place')
  metaTagByTitle('Us - Digital Music Shopping Market Place')
  metaTagByDesc('D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.')
  metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT')
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`)

  // Return us page
  return (
    <div className="us-page">
      <AnimationOnScroll animation="animate__bounce" delay={1}>
        <div className="page-title us-page-title">
          <h2 className="text-heading2">Us.</h2>
        </div>
      </AnimationOnScroll>
      {!isMobile ? (
        <div
          className="content"
          onWheel={isScrolling ? undefined : handleWheel}
        >
          <div className="tabs-wrapper">
            <Tabs className="tabs">
              {new Array(formattedUsData.length).fill(0).map((_, index) => (
                <Tab
                  key={`tab-${index}`}
                  className={classnames('tab', {
                    bottom: index > tabId,
                    top: index < tabId
                  })}
                  onClick={() => handleTabChange(index)}
                />
              ))}
            </Tabs>
          </div>
          <div className="tab-panels">
            {formattedUsData.map((panel, tIndex) => (
              <TabPanel
                key={`tab-panel-${tIndex}`}
                className={classnames('tab-panel', {
                  block: tIndex === tabId || tIndex === oldTabId,
                  [`slide-${slideDirection}`]: tIndex === tabId,
                  [`hidden-${slideDirection}`]: tIndex === oldTabId
                })}
              >
                {panel.map(
                  (
                    { title, description, width, position, bottom, top },
                    pIndex
                  ) => (
                    <div
                      key={`panel-item-${pIndex}`}
                      className={`panel-item ${width} ${position}`}
                      style={{ bottom, top }}
                    >
                      <h3 className="text-heading3">{title}</h3>
                      <p className="text-body1">{description}</p>
                    </div>
                  )
                )}
              </TabPanel>
            ))}
          </div>
        </div>
      ) : (
        <Stack className="mobile-view" spacing={20} direction="column">
          {formattedUsData.map((panel, pIndex) =>
            panel.map((item, index) => (
              <Box key={`panel-item-${pIndex}-${index}`} className="panel-item">
                <h3 className="text-heading3">{item.title}</h3>
                <p className="text-body1">{item.description}</p>
              </Box>
            ))
          )}
        </Stack>
      )}
    </div>
  );
};
