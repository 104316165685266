// Dependencies
import React, { FC } from 'react';
import { Button, Input, FormControl, FormHelperText, useToast } from '@chakra-ui/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// Components
import { AnimationOnScroll, Icon } from '../../../../components';

// Apis
import { AuthApi } from '../../../../apis';

// Constants
import { ROUTES } from '../../../../constants';

// Styles
import './styles.scss';
import { Alert } from '../../../../components';

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Email is not valid').required('Email is required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
    .matches(/\d/, 'Password should contain at least one number.')
    .min(8, 'Password should contain at least 8 characters.')
});

// Interfaces
interface ISignUpValues {
  name: string;
  email: string;
  username: string;
  password: string;
}

// Export sign-up page
export const ConfirmEmailPage: FC = () => {
  // Get history from hook
  const history = useHistory();

  // Get toast from hook
  const toast = useToast();

  // Submit handler
  const handleSubmit = (values: ISignUpValues, { setSubmitting }: FormikHelpers<ISignUpValues>) => {
    AuthApi.register(values)
      .then(() => {
        setSubmitting(false);
      })
      .catch((err) => {
        const message = err.msg || 'Something went wrong';
        setSubmitting(false);
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={message} color="red" onClose={onClose} />
        });
      });
  };

  // Login handler
  const handleLogin = () => {
    history.push(ROUTES.AUTH.SIGN_IN);
  };

  // Return sign-up page
  return (
    <div className="confirm-email-page">
      <AnimationOnScroll animation="animate__bounce" delay={4}>
        <div className="page-title auth-page-title">
          <h2 className="text-heading2">Confirm Email.</h2>
        </div>
      </AnimationOnScroll>
      <div className="content">
        <h2>
          To activate your account, Please check your inbox and click on the confirmation link we just sent you.
        </h2>
        <div className='email-info'>
          <AnimationOnScroll animation="animate__fadeIn" delay={2}>
            <span className="text-heading5 text-body3--lg">HAVEN'T RECIEVED OUR EMAIL?</span>
            <br />
            <span className="text-heading5 text-body3--lg">PLEASE CHECK YOUR SPAM FOLDER OR SELECT RESEND</span>
          </AnimationOnScroll>
        </div>
        <div className="action">
          <AnimationOnScroll animation="animate__fadeIn" delay={2}>
            <Button className="d-button d-button--full-width account-button" onClick={handleLogin}>
              Resend Confirmation Email
            </Button>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};
