// Dependencies
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Flex, Spinner, useToast } from '@chakra-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Components
import { Alert, AnimationOnScroll, ArticleView, Icon } from '../../components';
import { Category, Event, Song, Trending } from './components';
import {Video} from '../../assets/video/index';
import { IIcon } from '../../components';


// Store
import {getCategories, getUser} from '../../store/selectors';

// Apis
import { ProductApi } from '../../apis';

// Interfaces
import { IProduct } from '../../shared/interfaces';
import { ProductModel } from '../../shared/models';

// Constants
import { REACT_APP_API_ASSET_SERVER, ROUTES } from '../../constants';
import { PRODUCT_TYPE } from '../../shared/enums';
import Slider from 'react-slick';

// Styles
import './styles.scss';


interface IElementor {
  icon: IIcon,
  title: string,
  description: string
}
const elementorSection : Array<IElementor> = [
  {
    icon: 'pen-icon',
    title: 'Sign Up',
    description:'Signin up is fast, free and easy. All you need is a vaid email address and phone number.'
  },
  {
    icon:'gift-icon',
    title: 'Buy',
    description:'Use crypto, debit or credit card to purchase from your favourite artist.'
  },
  {
    icon:'cart-icon',
    title: 'Use',
    description:'Enjoy exciting utility like VIP concert tickets, IRL experience, and more.'
  },
  {
    icon:'finger-print',
    title: 'Gift',
    description:'1-click to gift your NFT to anyone with an email address.'
  }
]
// Export home page
export const HomePage: FC = () => {
  // States
  const [songs, setSongs] = useState<ProductModel[]>([]);
  const [events, setEvents] = useState<ProductModel[]>([]);
  const [sliderEvents, setSliderEvents] = useState<ProductModel[]>([]);
  const [isPlayed, setIsPlayed] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  // Ref
  const videoRef = useRef<HTMLVideoElement>(null);

  // Get toast from hook
  const toast = useToast();

  // Get history from hook
  const history = useHistory();

  // Get categories from store
  const categories = useSelector(getCategories);
  const user= useSelector(getUser)

  // Toggle play handler
  const handleTogglePlay = () => {
    if (isPlayed) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlayed(!isPlayed);
  };

  // Video ended handler
  const handleVideoEnded = () => {
    setIsPlayed(false);
  };

  // Go to events handler
  const handleGoToEvents = () => {
    history.push(ROUTES.EVENTS.INDEX);
  };

  // Go to products handler
  const handleGotoProducts = () => {
    history.push(ROUTES.PRODUCTS.INDEX);
  };
  //Go to dashnoard
    const handleGotoDashboard = () => {
        history.push(ROUTES.PROFILE.DASHBOARD);
    };
  //go to signup
    const handleGotoSignUp = () => {
        history.push(ROUTES.AUTH.SIGN_UP);
    };

  const showToast = useCallback(
    (msg: string) =>
      toast({
        position: 'top-right',
        render: ({ onClose }) => <Alert message={msg} color="red" onClose={onClose} />
      }),
    // eslint-disable-next-line
    []
  );

  const downloadProducts = useCallback(() => {
    const downloadSongs = ProductApi.readAll({
      query: {
        type: PRODUCT_TYPE.SONG,
        isFeatured: true
      }
    })
      .then((res) => {
        setSongs(res.products.map((product: IProduct) => new ProductModel(product)));
      })
      .catch((err) => {
        showToast(err.msg);
      });

    const downloadEvents = ProductApi.readAll({
      query: {
        type: PRODUCT_TYPE.VIRTUAL_EVENT,
        isFeatured: true
      }
    })
      .then((res) => {
        setEvents(res.products.map((product: IProduct) => new ProductModel(product)));
      })
      .catch((err) => {
        showToast(err.msg);
      });

    return Promise.all([downloadSongs, downloadEvents]);
    // eslint-disable-next-line
  }, []);

  // On mounted
  useEffect(() => {
    setLoading(true);
    downloadProducts().finally(() => setLoading(false));
    ProductApi.readAll()
      .then((res) => {
        setSliderEvents(res.products.map((product: IProduct) => new ProductModel(product)));
      })
      .catch((err) => {
        showToast(err.msg);
      });
    // eslint-disable-next-line
  }, [showToast]);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    vertical: true,
    verticalSwiping: true,
    pauseOnHover:false,
  };

  // Return home page
  return (
    <div className="home-page">
      <div className="lead">
          <video src={Video} muted={true} autoPlay={true} loop={true} className='video-content'>
          </video>
        <div className="lead-title">
          <h1 className="text-heading1 lead-title-nft">
            Limited Edition
            <br />
            Music <span className="nft">NFT</span>'s
          </h1>
          <div className="lead-action">
            <p className='text-body2'>
              Welcome to our online marketplace where you can buy and collect unique Music NFTs.
              Our carefully curated selection features rare and exclusive tracks from talented
              musicians and producers around the world.
            </p>
            <div className='lead-action-button'>
            <Button className='lead-explore'  onClick={handleGotoProducts}>
              Explore
            </Button>
            <Button className='lead-event' onClick={handleGoToEvents}>Event</Button>
            </div>
          </div>
        </div>
      </div>
      <div className='elementor-container'>
        {elementorSection.map((item, index)=>(
          <div key={index} className='elementor-box'>
            <Icon name={item.icon} height={50} width={50}/>
             <h1 className='text-heading3'>{item.title} </h1>
             <p className='text-body2 text--center'>{item.description}</p>
          </div>
        ))}
      </div>
      <div className='nft-section'>
        <div className='nft-section-left'>
        <h3 className='text-body2 text--cyan'>Collect NFTs from your favourite Music</h3>
          <p className='text-body1'>
            D-Music is the mass-consumer Web3 company connecting the next 100M non-crypto-native fans
            to iconic artists. D-Music technology enables global brands and enterprises to reimagine commerce,
            loyalty and engagement, allowing consumers to seamlessly collect or trade digital assets with
            no prior crypto expertise.
          </p>
          <div className='nft-button'>
            <Button className='nft-button-join'>
              Join for Free
            </Button>
            <Button className='nft-button-explore'>
              Explore
            </Button>
          </div>
        </div>
        <div className='nft-section-right' >
          {/*  @ts-ignore*/}
          <Slider  {...settings}>
            {songs.slice(0,10).map((item:ProductModel, index) => (
              <div key={index} className='img'>
                <img className="product-image" src={`${REACT_APP_API_ASSET_SERVER}/${item.thumbnail?.fieldname}/${item.thumbnail?.filename}`} alt={item.thumbnail?.filename} />
              </div>
            ))}
          </Slider>
          {/*  @ts-ignore*/}
          <Slider {...settings} rtl={true}>
            {sliderEvents.slice(0, 10).map((item, index) => (
              <div key={index} className='img'>
                <img className="product-image" src={`${REACT_APP_API_ASSET_SERVER}/${item.thumbnail?.fieldname}/${item.thumbnail?.filename}`} alt={item.thumbnail?.filename} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="songs-events">
        <AnimationOnScroll animation="animate__zoomIn" className="section-title">
          <h2 className="text-heading2">
            <span>Latest</span> <span>Songs</span>{' '}
            <span>&</span> <span>Events</span>
          </h2>
        </AnimationOnScroll>
        <div className="events-animation-wrapper">
          {events.map((event, index) => (
            <AnimationOnScroll key={index} animation="animate__backInRight" delay={1} className="backdrop-blur">
              <Event key={index} event={event} />
            </AnimationOnScroll>
          ))}
        </div>
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height={200}>
            <Spinner color="#00Ff00" size="xl" />
          </Flex>
        ) : (songs.map((product, index) => (
          <AnimationOnScroll key={index} animation="animate__backInRight">
            <Song product={product} />
          </AnimationOnScroll>
        )))}
      </div>
      <Trending />
      <div className="section category-section">
        <AnimationOnScroll animation="animate__zoomIn" isSubElement>
          <h2 className="text-heading2 section-title">Browse by Category</h2>
        </AnimationOnScroll>
        <div className="categories">
          {categories.slice(0,4).map((category, index) => (
            <AnimationOnScroll key={index} animation="animate__fadeIn" isSubElement>
              <Category category={category} />
            </AnimationOnScroll>
          ))}
        </div>
        <Button className='view-all' onClick={()=>{history.push('/category')}}>View All</Button>
      </div>
      <div className="section article-section">
        <AnimationOnScroll animation="animate__zoomIn" isSubElement>
          <h2 className="text-heading2 section-title">NEWS & Articles</h2>
        </AnimationOnScroll>
          <div style={{width:'100%'}}>
            <AnimationOnScroll animation="animate__fadeIn" isSubElement>
              <ArticleView />
            </AnimationOnScroll>
          </div>
      </div>
      <div className="section meet-section">
        <AnimationOnScroll animation="animate__zoomIn" isSubElement className={'title-container'}>
          <h2 className="text-heading2 section-title">Meet D-Music Clubhouse</h2>
          <p className="text-body1 text--center section-subtitle">Listen to your favourite Artist, live, in Metaverse!</p>
        </AnimationOnScroll>
        <div className="meet-section-content">
          <div className="meet-video">
            <div className="meet-video-description">
              <img src="/images/logo-filled.png" alt="logo" />
              <p className="text-body1">D-Music Clubhouse | Listen to your favourite Artist, live, in Metaverse!</p>
            </div>
            <video ref={videoRef} onClick={handleTogglePlay} onEnded={handleVideoEnded} />
            {isPlayed ? (
              <Icon name="video-pause" className="video-action" />
            ) : (
              <Icon name="video-play" className="video-action" />
            )}
          </div>
          <AnimationOnScroll animation="animate__fadeIn" isSubElement className="meet-description">
            <p className="text-body1">
              In <span className="text--cyan">D-Music's Clubhouse</span> you can join to other fans of your favourite
              Artist and enjoy the Music!
              <br />
              <br />
              All you need to do is just book your ticket and join{' '}
              <span className="text--cyan">To D-Music Clubhouse</span> on time!
            </p>
            <div className="meet-actions">
              <Button className="d-button d-button--cyan">CLUBHOUSE</Button>
              <Button className="d-outlined-button d-button--cyan" onClick={handleGoToEvents}>
                EVENTS LIST
              </Button>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="section explore-section">
        <h1 className="text-heading1 text--center">Explore Through the Most Exciting Music NFT's</h1>
        <div className="explore-action">
          <Button className="d-button" onClick={user?.id  ? handleGotoProducts: handleGotoSignUp}>
            {user?.id ? 'Explore':'Sign Up'}
          </Button>
          <Button className="d-outlined-button" onClick={user?.id  ? handleGotoDashboard: handleGotoProducts}>  {user?.id ? 'Go to Dashboard':'Explore'}</Button>
        </div>
      </div>
    </div>
  );
};
