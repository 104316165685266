import { ROLE } from '../enums';
import { IUser } from '../interfaces';
import { ProductModel } from './product.model';

export class UserModel {
  id: string;
  name: string;
  email: string;
  avatar: any;
  verify: boolean;
  birthday?: string;
  phoneNumber?: string;
  role: ROLE;
  notificationSettings: string[];
  favoriteProducts?: string[] | ProductModel[] | (string | ProductModel)[];
  createdAt: string;
  isKYCVerified: boolean;
  applicationId: string;
  accountWallet: string;
  chainId: number;

  constructor(data: IUser) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.avatar = data.avatar;
    this.verify = data.verify || false;
    this.isKYCVerified = data.isKYCVerified || false;
    this.birthday = data.birthday || '';
    this.phoneNumber = data.phoneNumber || '';
    this.applicationId = data.applicationId || '';
    this.role = data.role;
    this.notificationSettings = data.notificationSettings || [];
    this.accountWallet = data.accountWallet || '';
    this.chainId = data.chainId || 0;
    if (data.favoriteProducts) {
      this.favoriteProducts = data.favoriteProducts;
    }
    this.createdAt = data.createdAt || '';
  }
}
