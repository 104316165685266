// Http
import Http from './http';

// Interfaces
import { IQuery } from '../shared/interfaces';

// Export payment apis
export const getDepositAddress = (query: IQuery) => {
  return Http.get('/payment/depositAddress', query);
};

export const generateWithdrawal = (body: IQuery) => {
  return Http.post('/payment/withdraw', body);
};
