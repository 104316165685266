// Dependencies
import React, { ChangeEvent, FC, useRef } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { AnimationOnScroll, Icon, IIcon } from '../../components';

// Tabs
import { DashboardTab, LikedProductsTab, WalletTab, SettingsTab, PaymentMethodsTab } from './Tabs';

// Global constants
import { ROUTES } from '../../constants';

// Apis
import { UserApi } from '../../apis';

// Model
import { UserModel } from '../../shared/models';

// Store
import { setUser } from '../../store/actions';
import { getUser } from '../../store/selectors';

// Styles
import './styles.scss';
import { getUserImage } from '../../utils';

// Interfaces
interface IProfileLink {
  path: string;
  icon: IIcon;
  name: string;
}

// Constants
export const profileLinks: IProfileLink[] = [
  {
    path: ROUTES.PROFILE.DASHBOARD,
    icon: 'dashboard',
    name: 'Dashboard'
  },
  {
    path: ROUTES.PROFILE.LIKED_PRODUCTS,
    icon: 'heart-fill',
    name: 'Liked Products'
  },
  {
    path: ROUTES.PROFILE.WALLET.LIST,
    icon: 'wallet',
    name: 'Wallet'
  },
  {
    path: ROUTES.PROFILE.SETTINGS,
    icon: 'settings',
    name: 'Settings'
  },
  // {
  //   path: ROUTES.PROFILE.PAYMENT_METHODS,
  //   icon: 'payment',
  //   name: 'Payment Methods'
  // }
];

// Create profile page
export const ProfilePage: FC = () => {
  // Get pathname from hook
  const { pathname } = useLocation<Location>();

  // Refs
  const uploadRef = useRef<HTMLInputElement>(null);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get user from store
  const user = useSelector(getUser);

  // Avatar click handler
  const handleAvatarClick = () => {
    if (uploadRef) {
      uploadRef.current?.click();
    }
  };

  // Upload handler
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const avatar = files[0];
      const formData = new FormData();

      formData.append('avatar', avatar);

      if (user?.id) {
        UserApi.updateAvatar(user.id, formData)
          .then((res) => {
            const user = new UserModel(res.user);
            dispatch(setUser(user));
          })
          .catch((err) => console.log(err));
      }
    }
  };

  // Return profile page
  return (
    <div className="profile-page">
      <div className="content">
        <AnimationOnScroll animation="animate__fadeIn" isSubElement>
          <div className="navbar">
            <div className="profile">
              <div className="avatar" onClick={handleAvatarClick}>
                <img src={user?.avatar ? getUserImage(user) : '/images/avatar.png'} alt="avatar" />
                <div className="avatar-edit-mask">
                  <Icon name="edit" />
                </div>
              </div>
              <input ref={uploadRef} type="file" accept="image/*" className="avatar-upload" onChange={handleUpload} />
              <h3 className="text-heading3 text--center">{user?.name}</h3>
              <div>
                {user?.isKYCVerified ? (
                  <div className="kyc-container kyc-verified">
                    <h4>KYC Verified</h4>
                  </div>
                ) : (
                  <div className="kyc-container kyc-pending">
                    <h4>KYC Pending</h4>
                  </div>
                )}
              </div>
            </div>
            <div className="profile-tab-menu">
              {profileLinks.map(({ path, icon, name }, index) => (
                <AnimationOnScroll key={path} animation="animate__fadeIn" delay={index + 2}>
                  <NavLink exact className="nav-link" activeClassName="nav-link--active" to={path}>
                    <Icon name={icon} />
                    <span className="text-body1">
                      {index === profileLinks.length - 1 ? (
                        <>
                          {name.split(' ')[0]} <span className="sm-none">{name.split(' ')[1]}</span>
                        </>
                      ) : (
                        name
                      )}
                    </span>
                  </NavLink>
                </AnimationOnScroll>
              ))}
            </div>
            <NavLink
              exact
              className="settings-link"
              activeClassName="settings-link--active"
              to={ROUTES.PROFILE.SETTINGS}
            >
              <Icon name="settings" />
            </NavLink>
          </div>
        </AnimationOnScroll>
        <div className="tab-section">
          {pathname === ROUTES.PROFILE.DASHBOARD && <DashboardTab />}
          {pathname === ROUTES.PROFILE.LIKED_PRODUCTS && <LikedProductsTab />}
          {pathname === ROUTES.PROFILE.WALLET.LIST && <WalletTab />}
          {pathname === ROUTES.PROFILE.SETTINGS && <SettingsTab />}
          {/* {pathname === ROUTES.PROFILE.PAYMENT_METHODS && <PaymentMethodsTab />} */}
        </div>
        {/* {pathname === ROUTES.PROFILE.PAYMENT_METHODS && (
          <div className="payment-background">
            <div className="backdrop" />
            <img src="/images/bitcoin-icon-background.png" alt="bitcoin" />
            <img src="/images/dollar-icon-background.png" alt="dollar" />
          </div>
        )} */}
      </div>
    </div>
  );
};
