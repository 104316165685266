// Dependencies
import Axios from 'axios';

// Http
import Http from './http';

// Interfaces
import { IQuery, OrderStatusType, IOrder, VatType } from '../shared/interfaces';

// Export order apis
export const order = ({
  userId,
  totalPrice,
  name,
  note,
  isGift,
  email,
  phoneNumber,
  products,
  buyerIpAddress,
  fingerprint,
  crypto,
  paymentMethod,
  casperWalletKey,
  ethereumWalletKey
}: IOrder) => {
  return Http.post(`/checkout/orders`, {
    userId,
    totalPrice,
    name,
    note,
    isGift,
    email,
    phoneNumber,
    products,
    buyerIpAddress,
    fingerprint,
    crypto,
    paymentMethod,
    casperWalletKey,
    ethereumWalletKey
  });
};

export const readAll = (params?: IQuery) => {
  return Http.get('/checkout/orders', params);
};

export const read = (id: string) => {
  return Http.get(`/checkout/orders/${id}`);
};

export const getVat = (query: VatType) => {
  return Http.get(`/checkout/vat?amountToBePaid=${query.amountToBePaid}&buyerIpAddress=${query.buyerIpAddress}`);
};

export const updateOrderStatus = (body: OrderStatusType) => {
  return Http.patch(`/checkout/orders/status`, body);
};

export const getIpv4 = () => {
  return Axios.get(`https://api64.ipify.org/?format=json`);
};

export const getDetailsFromIp = (ip: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_IP_API_KEY}`,
    }
  }
  return Axios.get(`https://ipinfo.io/${ip}`, config);
};
