// Http
import Http from './http';

// Export privacy apis

// @ts-ignore
export const read = (): Promise<any> => {
    return Http.get('/privacy');
};

