// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

// Components
import { AnimationOnScroll, Icon } from '../../../components';

// Styles
import './styles.scss';
import { getTransactionDetails } from '../../../apis/product.api';

// Export order detail page
export const OrderNftDetailPage: FC = () => {
  // States
  const [nft, setNft] = useState<any>({});

  // Get params from hook
  const hash = useParams<{ tx: string }>();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get('name');

  const getTransaction = async () => {
    const res = await getTransactionDetails(hash?.tx as string);
    setNft(res.nft);
  };

  useEffect(() => {
    if (hash?.tx) {
      getTransaction();
    }
  }, [hash]);

  // Return order nft detail page
  return (
    <div className="order-nft-detail-page">
      <AnimationOnScroll animation="animate__bounce" delay={2.5}>
        <div className="page-title">
          <h2 className="text-heading2">{name}</h2>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animation="animate__slideInUp" delay={1.5} isSubElement className="detail-panel">
        <div className='detail-item'>
          <p className="text-body1">Network:</p>
          <p className="text-body1">{nft?.details?.chain}</p>
        </div>
        <hr className="divider" />
        <div className='detail-item'>
          <p className="text-body1">TokenId:</p>
          <p className="text-body1">{nft?.tokenId}</p>
        </div>
        <hr className="divider" />
        <div className='detail-item'>
          <p className="text-body1">To:</p>
          <p className="text-body1">{nft?.details?.to}</p>
        </div>
        <hr className="divider" />
        <div className='detail-item'>
          <p className="text-body1">TxHash:</p>
          <p className="text-body1">{nft?.details?.transactionHash}</p>
        </div>
        <hr className="divider" />
      </AnimationOnScroll></div>
  );
};
