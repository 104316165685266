// Dependencies
import React, { FC, useEffect, useState } from 'react';

// Components
import { Icon, Pagination } from '../../../components';

// Apis
import { ArtistApi } from '../../../apis';

// Interfaces
import { IArtist } from '../../../shared/interfaces';

// Styles
import './styles.scss';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants';

const pageLimit = 12;

// Export artists list page
export const ArtistListPage: FC = () => {
  // States
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [pageCnt, setPageCnt] = useState(0);

  // Get history from hook
  const history = useHistory();

  // Go to detail page handler
  const handleGoToDetail = (id: string) => () => {
    history.push(ROUTES.ARTIST.DETAIL.replace(':id', id));
  };

  // On mounted
  useEffect(() => {
    ArtistApi.readAll({
      options: {
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
        isFeatured: true
      }
    })
      .then((res) => {
        setArtists(res.artists);
        setPageCnt(Math.ceil(res.pagination.total / pageLimit));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageNumber]);

  // Return artist list page
  return (
    <div className="artist-list-page">
      <div className="page-title">
        <h2 className="text-heading2">Artists</h2>
      </div>
      <div className="content">
        <div className="artists">
          {artists.map(({ id, name, thumbnail  }, index) => (
            <div key={`item-${index}`} className="artist-card">
              <div className="artist-image-container" onClick={handleGoToDetail(id)}>
                <img src={thumbnail} alt="thumbnail"  />
              </div>
              <div className="artist-name-container">
                <p className="artist-name">
                  {name}
                  <Icon name="star-check" className="star-check-icon" />
                </p>
                <p>50k NFTs created </p>
              </div>
            </div>
          ))}
        </div>
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
