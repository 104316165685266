// Dependencies
import React, { useEffect, useState } from 'react';
import {
  Input,
  Switch,
  FormControl,
  FormHelperText,
  Button,
  useToast,
  Tab,
  Tabs,
  InputGroup,
  InputRightElement,
  FormLabel,
  Checkbox
} from '@chakra-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, AnimationOnScroll, Icon, IOption, Select } from '../../../../components';

import { getUser } from '../../../../store/selectors';
import { UserApi } from '../../../../apis';

// Styles
import './styles.scss';
import { setUser } from '../../../../store/actions';
import { UserModel } from '../../../../shared/models';
import {
  metaTagByDesc,
  metaTagByKey,
  metaTagByTitle,
  metaTagByWeb,
  TabTitle
} from '../../../../utils/generaltittlefunction';
import classnames from 'classnames';
import TextField from '../../../../components/TextField';
import { COUNTRIES } from '../../../../constants/countries';
import { CITIES, CURRENCIES, LANGUAGES } from '../../../../constants';

// Constants
const notificationSettings = [
  {
    name: 'Receipts',
    label: 'Receipts for purchase'
  },
  {
    name: 'Announcements',
    label: 'Listing announcements, New features, Updates and Regulation changes'
  },
  {
    name: 'Promotions',
    label: 'Latest promotions and Special Occasion offers'
  },
  {
    name: 'Blog',
    label: 'Blog and Newsletter'
  }
];
const tabData = [
  {
    title: 'Account'
  },
  {
    title: 'Shipping Address'
  },
  {
    title: 'Security'
  },
  {
    title: 'Notifications'
  },
  {
    title: 'Preferences'
  }
];
enum SETTINGS_TAB {
  ACCOUNT,
  SHIPPING_ADDRESS,
  SECURITY,
  NOTIFICATIONS,
  PREFERENCES
}
interface ISettings {
  name: string;
  email: string;
  username: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  region: string;
  zip: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  currency: string;
  language: string;
}
interface ISecurity {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}
interface ITextField {
  name: keyof ISettings;
  type?: 'text' | 'password' | 'select';
  options?: IOption[];
  label: string;
}

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().required('Email is required').email('Email is not valid!'),
  oldPassword: Yup.string()
    .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
    .matches(/\d/, 'Password should contain at least one number.')
    .min(8, 'Password should contain at least 8 characters.'),
  newPassword: Yup.string()
    .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
    .matches(/\d/, 'Password should contain at least one number.')
    .min(8, 'Password should contain at least 8 characters.'),
  confirmPassword: Yup.string()
    .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
    .matches(/\d/, 'Password should contain at least one number.')
    .min(8, 'Password should contain at least 8 characters.')
});

// Export settings tab
export const SettingsTab = () => {
  const [tabId, setTabId] = useState<number>(0);
  const [visible, setVisible] = useState<{
    oldPassword: boolean;
    newPassword: boolean;
    confirmPassword: boolean;
  }>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  });
  const [initialValues, setInitialValues] = useState<ISettings>({
    name: '',
    username: '',
    email: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    city: '',
    region: '',
    zip: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    currency: '',
    language: ''
  });
  const [notifications, setNotifications] = useState<string[]>([]);

  // Get user from store
  const user = useSelector(getUser);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get toast from hook
  const toast = useToast();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const newProfile = {
        name: values.name,
        email: values.email,
        username: values.username,
        phoneNumber: values.phoneNumber,
        notificationSettings: notifications
      };
      let password = null;

      if (values.oldPassword && values.newPassword) {
        password = {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword
        };
      }

      if (user?.id) {
        const updateProfileActions = [UserApi.updateProfile(user.id, newProfile)];

        if (password) {
          updateProfileActions.push(UserApi.updatePassword(user.id, password));
        }

        Promise.all(updateProfileActions)
          .then(() => {
            setSubmitting(false);

            const updatedUser = new UserModel({
              ...user,
              ...newProfile
            });
            dispatch(setUser(updatedUser));

            toast({
              position: 'top-right',
              render: ({ onClose }) => <Alert message="Successfully Updated" onClose={onClose} />
            });
          })
          .catch((err) => {
            const message = err.msg || 'Something went wrong';
            setSubmitting(false);
            toast({
              position: 'top-right',
              render: ({ onClose }) => <Alert message={message} color="red" onClose={onClose} />
            });
          });
      }
    }
  });

  const accountFields: ITextField[] = [
    {
      name: 'name',
      label: 'Full Name'
    },
    {
      name: 'username',
      label: 'User Name'
    },
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'phoneNumber',
      label: 'Mobile'
    }
  ];
  const addressFields: ITextField[] = [
    {
      name: 'addressLine1',
      label: 'Address Line 1'
    },
    {
      name: 'addressLine2',
      label: 'Address Line 2'
    },
    {
      name: 'country',
      label: 'Country',
      type: 'select',
      options: COUNTRIES
    },
    {
      name: 'city',
      label: 'City',
      type: 'select',
      options: CITIES
    },
    {
      name: 'region',
      label: 'State/Region'
    },
    {
      name: 'zip',
      label: 'ZIP/Postal Code'
    }
  ];
  const securityFields: ITextField[] = [
    {
      name: 'oldPassword',
      type: 'password',
      label: 'Current Password'
    },
    {
      name: 'newPassword',
      type: 'password',
      label: 'New Password'
    },
    {
      name: 'confirmPassword',
      type: 'password',
      label: 'Confirm New Password'
    }
  ];
  const preferenceFields: ITextField[] = [
    {
      name: 'currency',
      label: 'Currency',
      type: 'select',
      options: CURRENCIES
    },
    {
      name: 'language',
      label: 'Language',
      type: 'select',
      options: LANGUAGES
    }
  ];

  const handleNotificationChange = (name: string, e: any) => {
    if (user?.notificationSettings) {
      const notificationSettings = [...notifications] || [];
      if (e.currentTarget.checked) {
        const index = notificationSettings?.indexOf(name);
        notificationSettings.splice(index, 1);
      } else {
        notificationSettings.push(name);
      }
      setNotifications(notificationSettings);
    }
  };

  const handleToggleVisible = (field: keyof ISecurity) => () => {
    setVisible({
      ...visible,
      [field]: !visible[field]
    });
  };

  TabTitle('Settings - Digital Music Shopping Market Place');
  metaTagByTitle('Settings - Digital Music Shopping Market Place');
  metaTagByDesc(
    'D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.'
  );
  metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT');
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`);

  useEffect(() => {
    if (user) {
      setInitialValues({
        name: user.name,
        email: user.email,
        username: user.username || '',
        phoneNumber: user.phoneNumber || '',
        addressLine1: '',
        addressLine2: '',
        country: '',
        city: '',
        region: '',
        zip: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        currency: '',
        language: ''
      });
      setNotifications(user.notificationSettings || []);
    }
  }, [user]);

  // Return setting tab
  return (
    <div className="profile-settings-tab">
      <Tabs className="tabs">
        {tabData.map((tab, index) => (
          <Tab
            key={`tab-${index}`}
            className={classnames('tab', {
              active: index === tabId
            })}
            onClick={() => setTabId(index)}
          >
            {tab.title}
          </Tab>
        ))}
      </Tabs>
      {tabId === SETTINGS_TAB.ACCOUNT && (
        <AnimationOnScroll animation="animate__slideInRight" isSubElement delay={1}>
          <div className="form-group">
            {accountFields.map(({ name, label, type, options }, index) => (
              <TextField
                key={index}
                name={name}
                type={type}
                label={label}
                options={options}
                value={values[name]}
                isInvalid={Boolean(errors[name] && touched[name])}
                helperText={errors[name] && touched[name] ? String(errors[name]) : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ))}
          </div>
        </AnimationOnScroll>
      )}
      {tabId === SETTINGS_TAB.SHIPPING_ADDRESS && (
        <AnimationOnScroll animation="animate__slideInRight" isSubElement>
          <div className="form-group">
            {addressFields.map(({ name, type, label, options }, index) => (
              <TextField
                key={index}
                name={name}
                type={type}
                label={label}
                options={options}
                value={values[name]}
                isInvalid={Boolean(errors[name] && touched[name])}
                helperText={errors[name] && touched[name] ? String(errors[name]) : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ))}
          </div>
        </AnimationOnScroll>
      )}
      {tabId === SETTINGS_TAB.SECURITY && (
        <AnimationOnScroll animation="animate__slideInRight" isSubElement>
          <div className="form-group">
            {securityFields.map(({ name, type, label }, index) => (
              <TextField
                key={index}
                name={name}
                type={type}
                label={label}
                value={values[name]}
                visiblePassword={visible[name as keyof ISecurity]}
                isInvalid={Boolean(errors[name] && touched[name])}
                helperText={errors[name] && touched[name] ? String(errors[name]) : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                onToggleVisiblePassword={handleToggleVisible(name as keyof ISecurity)}
              />
            ))}
          </div>
        </AnimationOnScroll>
      )}
      {tabId === SETTINGS_TAB.NOTIFICATIONS && (
        <AnimationOnScroll animation="animate__slideInRight" isSubElement>
          <table className="notification-table">
            <thead>
              <tr>
                <td>Notifications</td>
                <td align="right">
                  <div className="head-label">
                    <Icon name="desktop-mac" />
                    <span>In App</span>
                  </div>
                </td>
                <td align="right">
                  <div className="head-label">
                    <Icon name="email" />
                    <span>Email</span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {notificationSettings.map(({ name, label }, index) => (
                <tr key={index}>
                  <td>
                    <h6>{name}</h6>
                    <span className="description">{label}</span>
                  </td>
                  <td align="right">
                    <Checkbox className="d-checkbox d-checkbox--filled d-checkbox--cyan" />
                  </td>
                  <td align="right">
                    <Checkbox className="d-checkbox d-checkbox--filled d-checkbox--cyan" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </AnimationOnScroll>
      )}
      {tabId === SETTINGS_TAB.PREFERENCES && (
        <div className="form-group">
          {preferenceFields.map(({ name, label, type, options }, index) => (
            <TextField
              key={index}
              name={name}
              type={type}
              label={label}
              options={options}
              value={values[name]}
              isInvalid={Boolean(errors[name] && touched[name])}
              helperText={errors[name] && touched[name] ? String(errors[name]) : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ))}
        </div>
      )}
      <Button className="d-button save-button" type="submit" isLoading={isSubmitting}>
        Save Changes
      </Button>
    </div>
  );
};
