// Dependencies
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import { CartButton } from '../../../../components';

// Styles
import './styles.scss';

// Interfaces
import { CURRENCY } from '../../../../shared/enums';
import { ROUTES } from '../../../../constants';
import { ProductModel } from '../../../../shared/models';
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../../../utils/generaltittlefunction';

interface IEventProps {
  event: ProductModel;
}

// Export product component
export const Event: FC<IEventProps> = ({ event }) => {
  // Return product component
  
  TabTitle('Home - Digital Music Shopping Market Place')
  metaTagByTitle('Home - Digital Music Shopping Market Place')
  metaTagByDesc('D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.')
  metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT')
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`)

  return (
    <div className="featured-event">
      <div className="featured-event-header">
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${event.id}`}>
          <h2 className="text-heading2 text--cyan">{event.name}</h2>
        </Link>
      </div>
      <hr />
      <div className="featured-event-body">
        <span className="text-body1">{event.categoryNames}</span>
        <CartButton
          color="cyan"
          productId={event.id as string}
          productPrice={event.price as number}
          productCurrency={event.currency as CURRENCY}
        />
      </div>
      <div className="featured-event-image">
          <div className={'image-content'}>
              <div className={'image-title'}>
                  <h2 className="text-heading2 text--white">{event.name}</h2>
                  <span className={'text-body1 text--white'}>{event.location}</span>
              </div>
              <span className={'image-location'}>D-Music's Clubhouse- Metaverse</span>
          </div>
        <img src={event.getThumbnailUrl} alt='event' width={'100%'} height={'100%'} />
      </div>
    </div>
  );
};
