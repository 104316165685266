import React, { useEffect } from 'react';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
// @ts-ignore
import queryString from 'query-string';
import { AuthApi } from '../../../apis';
import { ROUTES } from '../../../constants';
import { Alert } from '../../../components';
import { useToast } from '@chakra-ui/core';

export const Verify: FC = () => {
    const { search } = useLocation();
    const { token } = queryString.parse(search);
    const history = useHistory();
    // Get toast from hook
    const toast = useToast();

    useEffect(() => {
        AuthApi.verify({ token: token }).then((res) => {
            history.push(ROUTES.AUTH.SIGN_IN);
            toast({
                position: 'top-right',
                render: ({ onClose }) => <Alert message="Welcome. Your account is successfully verified." onClose={onClose} />
            });

        }).catch((err) => {
            console.log(err);
        });
    }, [history, toast, token])
    return (
        <>
        </>
    );
}
