// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';

// Components
import { Li, Ul } from '../../../../components';

// Constants
import { ROUTES } from '../../../../constants';

// Utils
import { convertToKebabCase } from '../../../../utils';

// Styles
import './styles.scss';
import { IFaqCategory } from '../../../../shared/models';
import { FaqApi } from '../../../../apis';

// Export FAQ group page
export const FAQGroupPage: FC = () => {
  const [categories, setCategories] = useState<IFaqCategory[]>([]);
  // Get params from hook
  const params = useParams<{ group: string }>();

  // Fetch categories
  const fetchCategories = () => {
    FaqApi.readCategories()
      .then((res) => {
        setCategories(res.faqCategories);
        console.log('category',res.faqCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // On mounted
  useEffect(() => {
    fetchCategories();
  }, []);

  // Get group from faq
  const group = useMemo(() => {
    return categories.find(({ title }) => convertToKebabCase(title).includes(params.group));
  }, [params, categories]);

  // Return FAQ group page
  return (
    <div className="faq-group-page">
      <div className="questions">
        {group?.questions.map((question, index) => (
          <Li
            key={index}
            to={ROUTES.HELP_CENTER.FAQ.DETAIL.replace(':group', convertToKebabCase(group.title)).replace(
              ':question',
              convertToKebabCase(question.title)
            )}
          >
            {question.title}
          </Li>
        ))}
      </div>
      <div className="group">
        <Ul>{group?.title || 'FAQ'}</Ul>
        <div className="nav-links">
          {categories.map(({ title }, index) => (
            <NavLink key={index} to={ROUTES.HELP_CENTER.FAQ.GROUP.replace(':group', convertToKebabCase(title))} exact>
              {title}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};
