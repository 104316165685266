// Dependencies
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from '@chakra-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Components
import Product from './Product';

// Store
import { getCart, getUser } from '../../store/selectors';

// Apis
import { CartApi, OrderApi } from '../../apis';

// Styles
import './styles.scss';
import { AnimationOnScroll } from '../../components';
import { addToCart } from '../../store/actions';
import { CartModel } from '../../shared/models/cart.model';
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../utils/generaltittlefunction';
import { validateCurrency } from '../../utils';

// Export cart page
export const CartPage: FC = () => {
  // States
  const [tax, setTax] = useState<any>();
  const [ipAddress, setIpAddress] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');

  // Get history from hook
  const history = useHistory();

  // Get cart from store
  const cart = useSelector(getCart);

  // Get user from store
  const user = useSelector(getUser);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Checkout handler
  const handleCheckout = () => {
    if (cart?.total) history.push('/checkout');
  };

  // On mounted
  useEffect(() => {
    OrderApi.getIpv4()
      .then((res) => setIpAddress(res.data.ip))
      .catch((err) => console.log(err));
  }, []);

  const setUserCart = useCallback(
    (userId: string) => {
      CartApi.read({
        fingerprint: userId
      })
        .then((res) => {
          dispatch(addToCart(new CartModel(res.cart)));
        })
        .catch((err) => {
          dispatch(addToCart(null));
          console.log(err);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    setUserCart(user?.id as string);
  }, [user, setUserCart]);

  // On cart changed
  useEffect(() => {
    if (cart && ipAddress) {
      OrderApi.getVat({
        amountToBePaid: cart?.total as number,
        buyerIpAddress: ipAddress
      })
        .then((res) => {
          OrderApi.getDetailsFromIp(ipAddress)
            .then((res) => {
              const timezone = res.data.timezone.split('/')[0];
              const currency = validateCurrency(timezone);
              setCurrency(currency);
            })
            .catch((err) => {
              console.log('🚀 ~ file: index.tsx:82 ~ .then ~ err:', err);
            });
          setTax(res.tax);
        })
        .catch((err) => console.log(err));
    }
  }, [cart, ipAddress]);
  console.log('>>>>', window.location);

  TabTitle('Cart - Digital Music Shopping Market Place');
  metaTagByTitle('Cart - Digital Music Shopping Market Place');
  metaTagByDesc(
    'D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.'
  );
  metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT');
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`);

  // Return cart page
  return (
    <div className="cart-page">
      <AnimationOnScroll animation="animate__bounce" delay={2.5}>
        <div className="page-title">
          <h2 className="text-heading2">Cart.</h2>
        </div>
      </AnimationOnScroll>
      <div className="content">
        <div className="product-list">
          <div className="lead">
            <h4 className="text-heading4">Product</h4>
            <h4 className="text-heading4">Price</h4>
          </div>
          <hr className="divider" />
          <div className="products">
            {cart?.products?.map((product) => (
              <AnimationOnScroll key={product.id} animation="animate__slideInRight">
                <Product product={product} />
              </AnimationOnScroll>
            ))}
          </div>
        </div>
        <AnimationOnScroll animation="animate__slideInUp" delay={1.5} isSubElement className="checkout-panel">
          <h4 className="text-heading4 checkout-panel-title">You're almost there!</h4>
          <AnimationOnScroll animation="animate__disappear" delay={1.5} isSubElement className="checkout-panel-divider" />
          <div className="summary">
            <div className="summary-content">
              <div className="sub-total">
                <h4 className="text-heading4 text-heading4--lg">Subtotal:</h4>
                <h4 className="text-heading4 text-heading4--lg">
                  {currency} {cart ? cart.total.toFixed(2) : 0}
                </h4>
              </div>
              <div className="sub-total">
                <h4 className="text-heading4 text-heading4--lg">VAT:</h4>
                <h4 className="text-heading4 text-heading4--lg">
                  {currency}
                  {tax ? tax.tax_amount : 0}
                </h4>
              </div>
              <div className="sub-total">
                <h3 className="text-heading3 text-heading3-md">Total:</h3>
                <h3 className="text-heading3 text-heading3-md text--lime">
                  {currency}
                  {((cart ? cart.total : 0) + (tax ? tax.tax_amount : 0)).toFixed(2)}
                </h3>
              </div>
            </div>
            {cart && cart?.products?.length > 0 && (
              <Button className="d-button check-out-button" onClick={handleCheckout}>
                Check out
              </Button>
            )}
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};
