// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@chakra-ui/core';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider, ExternalProvider, JsonRpcFetchFunc } from '@ethersproject/providers';

// App routes
import AppRoutes from './routes';

// Components
import { Splash } from './components/Splash';

// Store
import store from './store';
import { WalletServiceProvider } from './utils/wallet-service';


// Create app
const App: FC = () => {
  // States
  const [visiblePage, setVisiblePage] = useState(false);

  // On mounted
  useEffect(() => {
    setTimeout(() => setVisiblePage(true), 4000);
  }, []);

  const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) => {
    return new Web3Provider(provider);
  };

  // Return app
  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <WalletServiceProvider>
          <ThemeProvider>{visiblePage ? <AppRoutes /> : <Splash />}</ThemeProvider>
        </WalletServiceProvider>
      </Web3ReactProvider>
    </Provider>
  );
};

// Export app
export default App;
