import React, { FC } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormControl, FormHelperText, Input, useToast } from '@chakra-ui/core';
import * as Yup from 'yup';
import './styles.scss';
import { Alert, AnimationOnScroll } from '../../../components';
import { AuthApi } from '../../../apis';

// Interfaces
interface ISignInValues {
  email: string;
  submit?: string;
}

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid!').required('Email is required!'),
});

export const ForgotPassword: FC = () => {
  const toast = useToast();

  const handleSubmit = (values: ISignInValues, { setSubmitting }: FormikHelpers<ISignInValues>) => {
    const { email } = values;
    setSubmitting(true);
    if (email) {
      AuthApi.forgotPassword(email).then((res) => {
        setSubmitting(false);
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={res.msg} color={res.success ? 'lime' : 'red'} onClose={onClose} />
        });
      }).catch((err) => {
        setSubmitting(false);
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
        });
      });
    }
  };

  return (
    <div className="forgot-password-page">
      <AnimationOnScroll animation="animate__bounce" delay={2}>
        <div className="page-title auth-page-title">
          <h2 className="text-heading2">Forgot your password?</h2>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animation="animate__fadeIn" delay={1} className="content">            <Formik
        initialValues={{ name: '', email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <div className="form-content">
              <FormControl className="d-form-control" isInvalid={Boolean(errors.email && touched.email)}>
                <Input
                  className="d-form-input"
                  placeholder="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormHelperText className="d-form-helper-text">
                  {errors.email && touched.email && String(errors.email)}
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                className="d-button d-button--full-width"
                isLoading={isSubmitting}
              >
                Send Email
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      </AnimationOnScroll>
      <div className="hand-img" />
    </div>
  )
}
