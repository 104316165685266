// Dependencies
import React, { FC, useCallback, useEffect, useState } from 'react';

// Styles
import './styles.scss';
import { Li, Ul } from '../../../../../components';
import { IFaqCategory, IFaqQuestion } from '../../../../../shared/models';
import { convertToKebabCase } from '../../../../../utils';
import { ROUTES } from '../../../../../constants';
import { IArticle } from '../../../../../shared/interfaces';
import { ArticleApi } from '../../../../../apis';

// Interfaces
interface FAQDetailRightProps {
    group?: IFaqCategory;
    question?: IFaqQuestion;
    params?: any;
}

// Export FAQ page
export const FAQDetailRightPage: FC<FAQDetailRightProps> = ({group}) => {
    // State
    const [articles, setArticles] = useState<IArticle[]>([]);

    const fetchArticleData = useCallback(() => {
        return ArticleApi.readAll({
            options: {
                limit: 4,
                isFeatured: true,
                sort: {
                    updatedAt : 'desc'
                },
            },
        })
            .then((res) => {
                setArticles(res.articles);
            })
            .catch((err) => {
                console.log(err);
            });
    },[]);
    // On mounted
    useEffect(() => {
        fetchArticleData();
    }, [fetchArticleData]);

    return (
        <div className="group">
            <Ul to={ROUTES.HELP_CENTER.FAQ.GROUP.replace(':group', convertToKebabCase(group?.title || ''))}>
                {group?.title}
            </Ul>
            <div className="nav-links">
                {group?.questions.map((question, index) => (
                    <Li
                        key={index}
                        to={ROUTES.HELP_CENTER.FAQ.DETAIL.replace(':group', convertToKebabCase(group.title)).replace(
                            ':question',
                            convertToKebabCase(question.title)
                        )}
                    >
                        {question.title}
                    </Li>
                ))}
            </div>
            <div className='tags underline'>
                <img src="/images/tag.png" alt="vinyl" />
                <span className="text-heading4">Tags</span>
            </div>
            <div>
                <button>marketpalce</button>
                <button>widthdrawal</button>
                <button>credit</button>
            </div>
            <div className='tags underline'>
                <img src="/images/vinyl.png" alt="vinyl" />
                <span className="text-heading4">Related Articles</span>
            </div>
            <div className="nav-links">
                {articles.map((article, index) => (
                    <Li
                        key={index}
                        to={ROUTES.ARTICLE.DETAIL.replace(':id',article.id)}
                    >
                        {article.title}
                    </Li>
                ))}
            </div>
        </div>

    );
};
