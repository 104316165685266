// Dependencies
import React, { FC, useEffect, useState } from 'react';

// Components
import { Li, Ul } from '../../../../components';

// Global constants
import { ROUTES } from '../../../../constants';

// Utils
import { convertToKebabCase } from '../../../../utils';
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../../../utils/generaltittlefunction';

// Styles
import './styles.scss';
import { IFaqCategory } from '../../../../shared/models';
import { FaqApi } from '../../../../apis';

// Export FAQ page
export const FAQPage: FC = () => {
  const [categories, setCategories] = useState<IFaqCategory[]>([]);

  // Fetch categories
  const fetchCategories = () => {
    FaqApi.readCategories()
      .then((res) => {
        setCategories(res.faqCategories);
        console.log('category',res.faqCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // On mounted
  useEffect(() => {
    fetchCategories();
  }, []);

  // Return FAQ page
  TabTitle(` FAQ - Digital Music Shopping Market Place`)
  metaTagByTitle(`FAQ - Digital Music Shopping Market Place`)
  metaTagByDesc(`D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.`)
  metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT')
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`)

  return (
    <div className="faq-page">
      {categories.map(({ title, questions }, fIndex) => (
        <div key={fIndex}>
          <Ul to={ROUTES.HELP_CENTER.FAQ.GROUP.replace(':group', convertToKebabCase(title))}>{title}</Ul>
          {questions.map((question, qIndex) => (
            <Li
              key={qIndex}
              to={ROUTES.HELP_CENTER.FAQ.DETAIL.replace(':group', convertToKebabCase(title)).replace(
                ':question',
                convertToKebabCase(question.title)
              )}
            >
              {question.title}
            </Li>
          ))}
        </div>
      ))}
    </div>
  );
};
