// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// Components
import { ArticleView } from '../../../components';

// Apis
import { ArticleApi } from '../../../apis';

// Interfaces
import { IArticle } from '../../../shared/interfaces';

// Constants
import {REACT_APP_API_ASSET_SERVER} from '../../../constants';

// Styles
import './styles.scss';

// Export article detail page
export const ArticleDetailPage: FC = () => {
  // States
  const [article, setArticle] = useState<IArticle>();

  // Get id from hook
  const { id } = useParams<{ id: string }>();

  // On mounted
  useEffect(() => {
    ArticleApi.read(id)
      .then((res) => {
        setArticle(res.article);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  // Return article detail page
  return (
    <div className="article-detail-page">
      <div className="section detail-section">
        <img src={`${REACT_APP_API_ASSET_SERVER}/${article?.thumbnail?.fieldname}/${article?.thumbnail?.filename}`} alt="thumbnail" />
        <h2 className="text-heading2 mt-20">{article?.title}</h2>
        <span className="text-body2 text--cyan mt-5">{moment(article?.createdAt).format('DD MMMM YYYY')}</span>
        <span className="text-body2 mt-5">
          By <span className="text--cyan">{article?.author}</span>
        </span>
        <div className="article-list-page-content mt-20">
          {/*<h4 className="text-heading4 mt-20">{item.title}</h4>*/}
          <p className="text-body2 mt-15 ">{article?.description}</p>
        </div>
        <hr className="divider mt-15" />
        <p className="text-body2 mt-5">
          By <span className="text--cyan">{article?.author}</span>
        </p>
      </div>
      <div className="section article-slider">
        <h2 className="text-heading2 text--magenta text--center">More like this</h2>
        <ArticleView />
      </div>
    </div>
  );
};
