// Dependencies
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Spinner, useToast } from '@chakra-ui/core';

// Components
import { Alert, AnimationOnScroll, ProductCard } from '../../../../components';

// Apis
import { UserApi } from '../../../../apis';

// Interfaces
import { IProduct } from '../../../../shared/interfaces';

import { ProductModel } from '../../../../shared/models';

// Store
import { getFavorites } from '../../../../store/selectors';

// Styles
import './styles.scss';
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../../../utils/generaltittlefunction';

// Export liked-product tab
export const LikedProductsTab: FC = () => {
    // States
    const [favoriteProducts, setFavoriteProducts] = useState<ProductModel[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    // Get toast from hook
    const toast = useToast();

    // Get favorites from store
    const favorites = useSelector(getFavorites);

    // Fetch favorites
    const fetchFavorites = useCallback(() => {
        setLoading(true);
        UserApi.getFavorites()
            .then((res) => setFavoriteProducts(res.favoriteProducts.map((product: IProduct) => new ProductModel(product))))
            .catch((err) => {
                toast({
                    position: 'top-right',
                    render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
                });
            })
            .finally(() => setLoading(false));
    }, [toast]);

    // On mounted
    useEffect(() => {
        fetchFavorites();
    }, []);

    TabTitle('Liked Products - Digital Music Shopping Market Place')
    metaTagByTitle('Liked Products - Digital Music Shopping Market Place')
    metaTagByDesc('D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.')
    metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT')
    metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`)

    // Return liked-product tab
    return (
        <>
            {isLoading ? (
                <Flex justifyContent="center" alignItems="center" height={200}>
                    <Spinner color="#00Ff00" size="xl" />
                </Flex>
            ) : (
                <div className="liked-products-tab">
                    {favoriteProducts.map((product) => (
                        <AnimationOnScroll animation="animate__fadeIn" isSubElement>
                            <ProductCard key={product.id} product={product} />
                        </AnimationOnScroll>
                    ))}
                </div>
            )}
        </>
    );
};
