import Http, { HttpV2 } from './http';

import { IQuery } from '../shared/interfaces';

export const getGalleriesByArtist = (artistId: string, query: any) => {
  return HttpV2.get(`/gallery/${artistId}`, query);
};

export const readById = (galleryId: string) => {
  return HttpV2.get(`/gallery/detail/${galleryId}`);
};



