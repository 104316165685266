// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { Tab, TabPanel, Tabs, useToast } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';

// Components
import { Icon, Select, Alert, CartButton, Pagination } from '../../../components';


// Constants
import { ROUTES } from '../../../constants';

// Apis
import { ArtistApi, GalleryApi, UserApi } from '../../../apis';

// Interfaces
import { IArtist, IProduct } from '../../../shared/interfaces';
// Styles
import './styles.scss';
import { copyTextToClipboard } from '../../../utils';
import { REACT_APP_API_ASSET_SERVER } from '../../../constants';
import { CURRENCY } from '../../../shared/enums';

// Constants
const tabData = [
  {
    label: 'Galleries',
    status: 'Galleries',
  },
  {
    label: 'Event Tickets',
    status: 'Event Tickets',
  },
  {
    label: 'Favourited',
    status: 'Favourited',
  }
];
const pageLimit = 12;


// Export artist detail page
export const ArtistDetailPage: FC = () => {
  // States
  const [artist, setArtist] = useState<IArtist>();
  const [selectValue, setSelectValue] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [galleries, setGalleries] = useState([]);
  const [tabId, setTabId] = useState<number>(0);

  const fetchProduct = () => {
    GalleryApi.getGalleriesByArtist(id, {
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
      isFeatured: true
    }).then(res => {
      setPageCnt(Math.ceil(res.pagination.total / pageLimit));
      setGalleries(res.galleries);
    }).catch((err) => { console.log(err) })
  }

  // Get id from hook
  const { id } = useParams<{ id: string }>();


  // Get history from hook
  const history = useHistory();

  // Get toast from hook
  const toast = useToast();

  useEffect(() => {
    if (artist) {
      fetchProduct();
    }
  }, [artist, pageNumber]);


  // On mounted
  useEffect(() => {
    ArtistApi.read(id)
      .then((res) => {
        setArtist(res.artist);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  


  // Copy Address to Clipboard
  const copyAddress = () => {
    copyTextToClipboard(`
    instagram= ${artist?.instagram ? artist?.instagram : 'Not Provided'}
    spotify= ${artist?.spotify ? artist?.spotify : 'Not Provided'}
    twitter= ${artist?.twitter ? artist?.twitter : 'Not Provided'}
    discord= ${artist?.discord ? artist?.discord : 'Not Provided'}
    website= ${artist?.discord ? artist.discord : 'Not Provided'}
    etherum= ${artist?.ethereumWallet ? artist?.ethereumWallet : 'Not Provided'}
    casper= ${artist?.casperWallet ? artist?.casperWallet : 'Not Provided'} `);
    setTimeout(() => {
      toast({
        position: 'top-right',
        render: ({ onClose }) => <Alert message="Details copied to clipboard" onClose={onClose} />
      });
    }, 1500);
  };
  const walletOption = [{
    label: artist?.casperWallet ? artist.casperWallet : '--',
    value: 0
  },
  {
    label: artist?.casperWallet ? artist.casperWallet : '---',
    value: 1
  }];

  // Go to detail page handler
  const handleGoToDetail = (galleryId: string) => () => {
    history.push(`/artists/${id}/gallery/${galleryId}/detail`);
  };

  // Return artist detail page
  return (
    <div className="artist-detail-page">
      <div className="banner-wrapper">
        <div className="banner-container">
          <Carousel
            autoPlay={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            infiniteLoop={true}

          >
            {new Array(3).fill(1).map((item, index) => (
              <img key={index} src="/images/artists/banner.png" alt="banner" />
            ))}
          </Carousel>
        </div>
        <div className="artist-image-wrapper">
          <div className="artist-image-container">
            <img src={artist?.thumbnail} alt="artist_thumbnail" />
          </div>
        </div>
      </div>
      <div className="artist-detail-container">
        <div className="artist-info text--white">
          <div className="artist-header-container">
            <h1 className="text-heading2">{artist?.name}</h1>
            <div className="icons-container">
              <a href={artist?.discord ? artist?.discord : 'https://discord.com/'}><Icon name="discord" /></a>
              <a href={artist?.spotify ? artist?.spotify : 'https://open.spotify.com/'}><Icon name="spotify" /></a>
              <a href={artist?.twitter ? artist?.twitter : 'https://twitter.com/?lang=en'}><Icon name="twitter" /></a>
              <a href={artist?.instagram ? artist?.instagram : 'https://www.instagram.com/accounts/login/'}><Icon name="instagram" /></a>
              <a href={artist?.website}><Icon name="earth" /></a>
              <span className="bluetooth-icon-container" onClick={() => copyAddress()}>
                <Icon name="bluetooth" />
              </span>
              <Select
                options={walletOption}
                value={selectValue}
                onChange={(value) => setSelectValue(value)}
                className="select"
              />
            </div>
          </div>
          <h1 className="text-heading4">{artist?.name}</h1>
          <p className="artist-joined-at text-body4">   {moment(artist?.createdAt).format("DD MMMM YYYY")}</p>
          <p className="artist-description text-body3">
            {artist?.bio}
          </p>
        </div>
      </div>
      <div className="tabs-wrapper">
        <Tabs className="tabs">
          {tabData.map((tab, index) => (
            <Tab
              key={`tab-${index}`}
              className={classnames('tab', {
                active: index === tabId
              })}
              onClick={() => setTabId(index)}
            >
              {tab.label}
            </Tab>
          ))}
        </Tabs>
        <div className="tab-panels">

          {tabData.map((panel, tIndex) => (
            <TabPanel
              key={`tab-panel-${tIndex}`}
              className={classnames('tab-panel', {
                block: tIndex === tabId
              })}
              isSelected={tIndex === tabId}
            >
              <div className="content">
                <div className="galleries">
                  {galleries.map(({ _id, name, thumbnail }, index) => (
                    <div key={`item-${index}`} className="artist-card">
                      <div className="artist-image-container" onClick={handleGoToDetail(_id)}>
                        <img src={thumbnail} alt="thumbnail" />
                      </div>
                      <div className="artist-name-container">
                        <p className="artist-name">
                          {name}
                          <Icon name="star-check" className="star-check-icon" />
                        </p>
                        <p>50k NFTs created </p>
                      </div>
                    </div>
                  ))}
                </div>
                <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
              </div>
            </TabPanel>
          ))}
        </div>
      </div>

    </div>
  );
};
