// Dependencies
import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Constants
import { REACT_APP_ENVIRONMENT, ROUTES } from '../../../constants';

// Styles
import './styles.scss';

// Constants
const navLinks = [
  { label: 'Home', pathname: ROUTES.HELP_CENTER.INDEX },
  { label: 'FAQ', pathname: ROUTES.HELP_CENTER.FAQ.INDEX },
  { label: 'Ticket', pathname: ROUTES.HELP_CENTER.TICKETS.INDEX }
];

// Create header component
const Header: FC = () => {
  // States

  // Return header component
  return (
    <header className="d-help-center-header">
      <div className="content">
        <Link to={ROUTES.HOME} className="logo">
          <img src={REACT_APP_ENVIRONMENT === 'demo' ? '/images/demo-logo.png' : '/images/logo.png'} alt="logo" />
        </Link>
        <div className="nav-links">
          {navLinks.map(({ label, pathname }, index) => (
            <div key={index} className="nav-link">
              <NavLink key={pathname} to={pathname}>
                {label}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
